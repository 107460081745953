import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Card, Typography} from 'antd';
import {AlertOutlined} from '@ant-design/icons';

interface AlertCardProps {
    style?: React.CSSProperties;
    onClick?: () => void;
}

export const AlertCard = (props: AlertCardProps) => {
    const {t} = useTranslation();

    return (
        <Card style={props.style} title={t('translation:alert.card.title')}>
            <Typography.Paragraph>
                {t('translation:alert.card.description')}
            </Typography.Paragraph>
            <Button
                type={'primary'}
                icon={<AlertOutlined />}
                onClick={props.onClick}>
                {t('translation:alert.card.action')}
            </Button>
        </Card>
    );
};
