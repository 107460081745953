import {useTranslation} from 'react-i18next';
import {Button, Card, Result, Space, Spin} from 'antd';
import {CsvSyncContentContainer} from './ContentContainer';
import React from 'react';
import {AxiosError} from 'axios';
import {formatAxiosError} from '../../../components/error/ErrorHandlerProvider';

export const CsvStepDone = (props: {
    done: boolean;
    error: AxiosError | undefined;
    onFinish: () => void;
}) => {
    const {t} = useTranslation();

    const errorText = props.error ? formatAxiosError(props.error) : '';
    let errorContext = '';
    if (props.error?.response?.data) {
        const data = props.error.response.data as {
            context?: {
                employeeNumber?: string;
            };
        };

        if (data.context?.employeeNumber) {
            errorContext = data.context.employeeNumber;
        }
    }

    return (
        <Space style={{width: '100%', height: '100%'}} direction={'vertical'}>
            <CsvSyncContentContainer>
                <Space
                    direction={'vertical'}
                    style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '30rem',
                    }}>
                    {!props.done && (
                        <Space
                            style={{width: '100%', justifyContent: 'center'}}>
                            <Spin />
                        </Space>
                    )}
                    {props.done && !props.error && (
                        <>
                            <Card style={{width: '20rem'}}>
                                <Result status={'success'}></Result>
                                {t('csvSync.step.done.success')}
                            </Card>
                        </>
                    )}
                    {props.done && props.error && (
                        <>
                            <Card style={{width: '20rem'}}>
                                <Result status={'error'}></Result>
                                {errorText}
                                {errorContext && (
                                    <small>
                                        {' '}
                                        (
                                        {t(
                                            'translation:employee.employeeNumber',
                                        )}
                                        :{' ' + errorContext})
                                    </small>
                                )}
                            </Card>
                        </>
                    )}
                </Space>
            </CsvSyncContentContainer>
            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                {props.done && (
                    <Button type={'primary'} onClick={props.onFinish}>
                        {t('edit.ok')}
                    </Button>
                )}
            </Space>
        </Space>
    );
};
