import {EmergencyContactGroupEditTable} from './component/EmergencyContactGroupEditTable';
import {Card, Typography} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {InvalidateReceiver} from '../../components/invalidate/Invalidate';

export const EmergencyContactGroupPage = () => {
    const {t} = useTranslation();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
            }}>
            <Typography.Title>
                {t('emergencyContactGroup.item')}
            </Typography.Title>
            <Card>
                <EmergencyContactGroupEditTable />
            </Card>
        </div>
    );
};
