import React from 'react';
import {Typography} from 'antd';
import BugImg from './undraw_bug_fixing_oc-7-a.svg';
import i18n from 'i18next';

export interface ErrorBoundaryProps {
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean,
    error: any
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error: any) {
        return {hasError: true, error: error};
    }


    componentDidUpdate(prevProps: ErrorBoundaryProps) {
        console.log('props', this.props);
        if (this.props.children != prevProps.children) {
            this.setState({hasError: false, error: null});
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        const {t} = i18n;
        if (this.state.hasError) {
            return <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{maxWidth: '50rem',}}>
                    <Typography.Title style={{
                        position: 'relative',
                        top: '5rem',
                        left: '-20rem',
                        fontSize: '3rem',
                    }}>{t('error.bug')}</Typography.Title>
                    <img src={BugImg} alt={'not found :/'}/>
                </div>
            </div>;
        } else {
            return this.props.children;
        }
    }

}