import {App, ModalFuncProps} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

/**
 * creates a callback for a large modal given a content function.
 * @param content
 * @param options
 */
export const useLargeModal = <T,>(
    content: (close: () => void, args: T) => React.ReactNode,
    options: ModalFuncProps = {},
) => {
    const {modal} = App.useApp();

    return (args: T) => {
        const modelRef: {current: null | {destroy: () => void}} = {
            current: null,
        };
        return (modelRef.current = modal.info({
            footer: null,
            icon: null,
            closable: true,
            closeIcon: <CloseOutlined />,
            width: '75rem',
            content: content(() => modelRef.current?.destroy(), args),
            ...options,
        }));
    };
};
