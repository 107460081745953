import React from 'react';
import {Form, Input, Popover, theme} from 'antd';
import {EditableColumnType} from './EditTable';
import {ExclamationCircleFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {Rule} from 'rc-field-form/lib/interface';
import SkeletonInput from 'antd/es/skeleton/Input';

export interface EditableCellProps<T> {
    children: React.ReactNode;
    dataIndex: keyof T;
    column: EditableColumnType<T>;
    record: T;
    editable:
        | boolean
        | {
              content: (record: T) => React.ReactNode;
              rules?: Rule[];
          };
    useSkeleton?: boolean;
}

export const ErrorPopup = (props: {error: string}) => {
    const {
        token: {colorWarning},
    } = theme.useToken();

    return (
        <Popover
            placement={'bottom'}
            content={
                <>
                    <ExclamationCircleFilled style={{color: colorWarning}} />{' '}
                    {props.error}
                </>
            }
            open={true}>
            <div style={{width: '100%'}}></div>
        </Popover>
    );
};

export const EditableCell = <T,>(props: EditableCellProps<T>) => {
    const {t} = useTranslation();

    const {
        editable,
        children,
        dataIndex,
        record,
        column,
        useSkeleton,
        ...restProps
    } = props;

    const rules =
        editable === true
            ? [
                  {
                      required: column.required || false,
                      message: (
                          <ErrorPopup
                              error={t('translation:edit.errorRequired', {
                                  field: t(column.title as string),
                              })}
                          />
                      ),
                  },
                  {
                      pattern: column.regex,
                      message: (
                          <ErrorPopup
                              error={t('translation:edit.errorRegex', {
                                  field: t(column.title as string),
                              })}
                          />
                      ),
                  },
              ]
            : [];

    let childNode = children;
    if (useSkeleton) {
        childNode = <SkeletonInput />;
    } else if (editable) {
        childNode = (
            <Form.Item
                validateDebounce={500}
                rules={
                    typeof editable === 'object'
                        ? editable.rules || rules
                        : rules
                }
                style={{margin: 0}}
                name={dataIndex as string}>
                {(() => {
                    if (editable === true) {
                        return <Input />;
                    } else {
                        return editable.content(record);
                    }
                })()}
            </Form.Item>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
