import React, {MutableRefObject} from 'react';
import {
    SearchableTable,
    SearchableTableActions,
} from '../../../components/table/SearchableTable';
import {Api} from '../../../api/endpoints';
import {
    ContactData,
    ContactDataKind,
    EmergencyContact,
} from '../../../model/EmergencyContact';
import {useTranslation} from 'react-i18next';
import {
    EditableColumnType,
    EditableTable,
} from '../../../components/table/EditTable';
import {App, Button, Checkbox, Dropdown, Modal, Tag} from 'antd';
import {useInvalidate} from '../../../components/invalidate/Invalidate';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {EmergencyContactGroup} from '../../../model/EmergencyContactGroup';
import {Color, uuidToTagColor} from '../../../common/Color';
import {CheckOutlined, StopOutlined} from '@ant-design/icons';

export interface EmergencyContactTableProps {
    onAdd: () => void;
    onEdit: (c: EmergencyContact) => void;
}

export const EmergencyContactTable = (props: EmergencyContactTableProps) => {
    const {t} = useTranslation();
    const {invalidate} = useInvalidate();
    const errorHandling = useErrorHandling();
    const {modal} = App.useApp();

    let columns: EditableColumnType<EmergencyContact>[] = [
        {
            fixed: 'left',
            sorter: true,
            dataIndex: ['employee', 'employeeNumber'],
            title: t('employee.employeeNumberShort'),
            key: 'employeeNumber',
            weight: 1.5,
        },
        {
            sorter: true,
            dataIndex: ['employee', 'firstName'],
            key: 'firstName',
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: ['employee', 'lastName'],
            key: 'lastName',
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'role',
            key: 'role',
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'emergencyContactGroup',
            key: 'emergencyContactGroup',
            render: (groups: EmergencyContactGroup[]) => {
                return groups.map(g => (
                    <Tag color={uuidToTagColor(g.id)}>
                        <small>{g.name}</small>
                    </Tag>
                ));
            },
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'active',
            key: 'active',
            render: (v: boolean) =>
                v ? (
                    <CheckOutlined style={{color: Color.PrimaryRed}} />
                ) : (
                    <StopOutlined />
                ),
            weight: 1,
        },
        {
            sorter: true,
            dataIndex: 'public',
            key: 'public',
            render: (v: boolean) =>
                v ? (
                    <CheckOutlined style={{color: Color.PrimaryRed}} />
                ) : (
                    <StopOutlined />
                ),
            weight: 1,
        },
        {
            sorter: false,
            dataIndex: 'phone',
            key: 'phone',
            render: (_: any, contact) => (
                <ContactDataDisplay
                    contactData={contact.contactData ?? []}
                    filter={ContactDataKind.PHONE}
                />
            ),
            weight: 1.5,
        },
        {
            sorter: false,
            dataIndex: 'email',
            key: 'email',
            render: (_: any, contact) => (
                <ContactDataDisplay
                    contactData={contact.contactData ?? []}
                    filter={ContactDataKind.EMAIL}
                />
            ),
            weight: 1.5,
        },
        {
            sorter: false,
            dataIndex: 'place',
            key: 'place',
            render: (_: any, contact) => (
                <ContactDataDisplay
                    contactData={contact.contactData ?? []}
                    filter={ContactDataKind.PLACE}
                />
            ),
            weight: 1.5,
        },
        {
            sorter: true,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
        {
            sorter: true,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
    ];

    columns = columns.map(col => {
        col.title = col.title || t(`emergencyContact.${col.key}`);
        return col;
    });

    const searchItems: SearchBarItem[] = [
        {
            value: 'inName',
        },
        {
            value: 'inEmployeeNumber',
        },
        {
            value: 'inRole',
        },
    ].map(item => {
        return {
            value: item.value,
            label: t(
                `emergencyContact.${
                    item.value[2].toLowerCase() + item.value.slice(3)
                }`,
            ),
        };
    });

    return (
        <EditableTable<EmergencyContact, EmergencyContact>
            searchbarItems={searchItems}
            columns={columns}
            newEmpty={() => {
                throw new Error('Method not implemented.');
            }}
            find={params => Api.emergencyContact.find(100, params)}
            delete={async contact => {
                await Api.emergencyContact.delete(contact);
            }}
            itemName={count => t('emergencyContact.item', {count})}
            customOnCreate={props.onAdd}
            actionButton={{
                label: t('edit.edit'),
                action: record => {
                    props.onEdit(record);
                },
            }}
        />
    );
};

const ContactDataDisplay = (props: {
    contactData: ContactData[];
    filter: string;
}) => {
    return (
        <>
            {(props.contactData || [])
                .filter(e => e.kind == props.filter)
                .map(e =>
                    e.label ? (
                        <>
                            <b>{e.label}: </b>
                            {e.value}
                            <br />
                        </>
                    ) : (
                        <>
                            {e.value}
                            <br />
                        </>
                    ),
                )}
        </>
    );
};
