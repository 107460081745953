import {useTranslation} from 'react-i18next';
import React from 'react';
import {RcFile} from 'antd/lib/upload';
import {resizeImage} from '../../../common/util/imageResize';
import {Form, Typography} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import {PictureFilled} from '@ant-design/icons';
import {Api} from '../../../api/endpoints';

export interface ImageDraggerValue {
    blobId: string | undefined;
    base64?: string;
    base64url?: string;
}

/**
 * Input for images. Images are encoded in base64. Note: Value cannot be set.
 */
export const ImageDragger = (props: {
    value?: ImageDraggerValue;
    onChange?: (value: ImageDraggerValue) => void;
}) => {
    const {t} = useTranslation();

    const onChange = (file: RcFile) => {
        props.onChange?.({
            blobId: props.value?.blobId,
        });
        const width = 1920;
        resizeImage(file, width, (width * 9) / 16).then(img => {
            console.log(img);
            props.onChange?.({
                blobId: props.value?.blobId,
                base64url: img,
                base64: img.split(',', 2)[1].trim(),
            });
        });

        return false;
    };

    return (
        <Dragger beforeUpload={onChange} accept={'image/*'} maxCount={1}>
            <div
                style={{
                    marginTop: -16,
                    marginBottom: -16,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    minHeight: '22rem',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundImage: props.value?.blobId
                        ? `url(${Api.imageBlobUrl(props.value?.blobId)})`
                        : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    overflow: 'hidden',
                }}>
                {props.value?.base64url && (
                    <img
                        src={props.value?.base64url}
                        style={{width: '100%'}}
                        alt={'Alert Cover Image'}
                    />
                )}
                {!props.value?.base64url && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            alignItems: 'center',
                            backgroundColor: props.value?.blobId
                                ? 'rgba(125,125,125,0.3)'
                                : 'none',
                        }}>
                        <PictureFilled
                            style={{
                                fontSize: '5rem',
                            }}
                        />
                        <Typography.Text>
                            {t('edit.dragAndDropImage')}
                        </Typography.Text>
                    </div>
                )}
            </div>
        </Dragger>
    );
};
