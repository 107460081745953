import WarningVest from './icons/lifejacket.svg';
import CrisisTeam from './icons/krisenstab.svg';
import ITCrisisTeam from './icons/itGroupIcon.svg';
import TriangleWarning from './icons/triangleWarning.svg';
import Flame from './icons/flame.svg';
import Megaphone from './icons/megaphone.svg';
import Computer from './icons/computer.svg';
import React from 'react';

export const AlertIconMap: {[name: string]: string} = {
    // first is Default
    triangleWarning: TriangleWarning,
    //
    warningVest: WarningVest,
    crisisTeam: CrisisTeam,
    itCrisisTeam: ITCrisisTeam,
    flame: Flame,
    megaphone: Megaphone,
    computer: Computer,
};

const Sizes = {
    small: 16,
    medium: 24,
};

export const AlertIcon: React.FC<{
    iconName: string;
    size?: 'small' | 'medium';
    style?: React.CSSProperties;
}> = props => {
    const key = AlertIconMap[props.iconName] || AlertIconMap.triangleWarning;
    const size = Sizes[props.size || 'medium'];

    return (
        <img
            src={key}
            style={{
                width: size,
                height: size,
                ...(props.style ?? {}),
            }}
        />
    );
};
