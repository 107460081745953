import {ReceiverGroup} from '../../model/ReceiverGroup';
import {PresetColors} from 'antd/es/theme/internal';
import {Tag} from 'antd';
import React from 'react';

export const ReceiverGroupTag = (props: {receiverGroup: ReceiverGroup}) => {
    const groupNameToPresetColor = (g: ReceiverGroup) => {
        const t = new Date(g.updatedAt).getTime();
        if (t >= 0) {
            return PresetColors[t % PresetColors.length];
        } else {
            return undefined;
        }
    };

    return (
        <Tag
            style={{margin: 2}}
            color={groupNameToPresetColor(props.receiverGroup)}>
            <small>{props.receiverGroup.name}</small>
        </Tag>
    );
};
