import {useErrorHandling} from '../error/ErrorHandlerProvider';
import React from 'react';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Api} from '../../api/endpoints';
import {Form, Select} from 'antd';

export const ReceiverSelection = (props: {
    value?: string[];
    onChange?: (value: string[]) => void;
    setCount: (n: number) => void;
    setLoading: (b: boolean) => void;
}) => {
    const errorHandler = useErrorHandling();

    const [groups, setGroups] = React.useState<ReceiverGroup[]>([]);

    React.useEffect(() => {
        Api.receiverGroup.list().then(setGroups).catch(errorHandler);
    }, []);

    const options = groups.map(g => ({
        label: g.name,
        value: g.id,
    }));

    function createSearchFilter(receiverGroupIds: string[]) {
        const filters: {[key: string]: string} = {
            mode: 'or',
        };

        for (let i = 0; i < receiverGroupIds.length; i++) {
            filters[`byReceiverGroupId[${i}]`] = receiverGroupIds[i];
        }

        return filters;
    }

    React.useEffect(() => {
        props.setLoading(true);
        Api.employee
            .find()
            .raw(0, 0, createSearchFilter(props.value ?? []))
            .then(page => {
                props.setCount(page.total);
                props.setLoading(false);
            })
            .catch(e => {
                errorHandler(e);
                // if loading fails, the button can not be clickable
                props.setLoading(true);
            });
    }, [JSON.stringify(props.value ?? [])]);

    return (
        <Select
            style={{
                width: '100%',
            }}
            mode={'multiple'}
            value={props.value}
            onChange={props.onChange}
            options={options}
        />
    );
};
