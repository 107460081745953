import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {CsvSyncContentContainer} from './ContentContainer';
import Dragger from 'antd/es/upload/Dragger';
import Papa from 'papaparse';
import {InboxOutlined} from '@ant-design/icons';
import React from 'react';

export const CsvStepUpload = (props: {
    onFinish: (content: {[key: string]: string}[]) => void;
    onAbort: () => void;
}) => {
    const {t} = useTranslation();

    return (
        <Space style={{width: '100%'}} direction={'vertical'}>
            <CsvSyncContentContainer>
                <Dragger
                    accept={'.csv, .tsv, text/csv, text/tab-separated-values'}
                    onChange={info => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const csvResult = reader.result as string;
                            const parsed = Papa.parse<{[key: string]: string}>(
                                csvResult,
                                {
                                    header: true,
                                    skipEmptyLines: true,
                                },
                            );
                            props.onFinish(parsed.data);
                        };
                        reader.readAsText(info.file.originFileObj as any);
                    }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        {t('csvSync.step.upload.dragger')}
                    </p>
                </Dragger>
            </CsvSyncContentContainer>
            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={props.onAbort}>{t('edit.abort')}</Button>
            </Space>
        </Space>
    );
};
