import {App} from 'antd';
import React, {createContext, useContext} from 'react';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import {NotificationPlacement} from 'antd/es/notification/interface';
import {translation} from '../../i18n/translation';

export type ErrorHandlerContextType = (e: Error) => void;
const ErrorHandlerContext = createContext<ErrorHandlerContextType>(e => {
    throw e;
});

interface Props {
    children: React.ReactNode;
}

export const useErrorHandling = () => {
    return useContext(ErrorHandlerContext);
};

export const formatAxiosError = (error: any) => {
    // Docs @ https://axios-http.com/docs/handling_errors
    const i18n = translation;
    const t = i18n.t;

    if (typeof error === 'string') {
        return error;
    }

    if (error.response) {
        // Response, but error
        console.error(error);

        let body;
        try {
            body = error.response.data as {
                code?: string;
                context?: {[key: string]: string};
            };
        } catch (e) {
            console.log(e);
        }
        const codeKey = `error.app.${body?.code || ''}`;
        console.log(codeKey);
        if (i18n.exists(codeKey)) {
            return t(codeKey, body?.context);
        }
        const statusKey = `error.status.${error.response.status}`;
        if (i18n.exists(statusKey)) {
            return t(statusKey);
        }

        const method = error.request?.method || 'GET';
        const methodKey = `error.method.${method.toUpperCase()}`;
        return t(methodKey);
    } else if (error.request) {
        return t('error.network');
    }
    throw error;
};

export const ErrorHandlerProvider = (props: Props) => {
    const {notification} = App.useApp();
    const defaultArgs = {
        duration: 15,
        placement: 'top' as NotificationPlacement,
    };

    const axiosHandler = (error: AxiosError) => {
        // Docs @ https://axios-http.com/docs/handling_errors
        const message = formatAxiosError(error);
        notification.error({
            message: message,
            ...defaultArgs,
        });
    };

    const handler = (e: Error) => {
        if (e instanceof AxiosError) {
            axiosHandler(e);
        } else {
            throw e;
        }
    };

    return (
        <ErrorHandlerContext.Provider value={handler}>
            {props.children}
        </ErrorHandlerContext.Provider>
    );
};
