import {App, Button, Card, Modal, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {CSVModal} from '../../csvImport/CSVModal';
import React from 'react';
import {Api} from '../../../api/endpoints';
import {CSVSyncRow} from '../../../model/core';
import {Employee} from '../../../model/Employee';
import Papa from 'papaparse';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';

interface CSVCardProps {
    style?: React.CSSProperties;
}

export const CSVCard = (props: CSVCardProps) => {
    const {t} = useTranslation();

    const handleError = useErrorHandling();
    const [showModal, setShowModal] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);

    return (
        <Card style={props.style} title={t('csvSync.card.title')}>
            <Typography.Paragraph>
                {t('csvSync.description')}
            </Typography.Paragraph>
            <Space>
                <Button onClick={() => setShowModal(true)}>
                    {t('csvSync.card.action')}
                </Button>
                <Button
                    loading={downloading}
                    onClick={() => {
                        setDownloading(true);
                        exportCsv()
                            .then()
                            .catch(handleError)
                            .finally(() =>
                                window.setTimeout(
                                    () => setDownloading(false),
                                    2500,
                                ),
                            );
                    }}
                    type={'dashed'}>
                    {t('csvSync.card.download')}
                </Button>
            </Space>
            <CSVModal open={showModal} closeFn={() => setShowModal(false)} />
        </Card>
    );
};

const exportCsv = async () => {
    const employees = await Api.employee.find().all();
    employees.sort((a, b) => {
        // Sort by numeric employeeNumber also ID
        const cpA = a.employeeNumber?.padStart(16, '0') ?? a.id;
        const cpB = b.employeeNumber?.padStart(16, '0') ?? b.id;

        return cpA.localeCompare(cpB);
    });
    const csvRows: CSVSyncRow[] = employees
        .filter((e: Employee) => e.employeeNumber)
        .map((e: Employee) => {
            return {
                employeeNumber: e.employeeNumber || '???',
                firstName: e.firstName,
                lastName: e.lastName,
                email: e.email,
                department: e.department,
                phone: e.phone,
            };
        });

    const csv = Papa.unparse(csvRows, {header: true});
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'employees.csv');
    link.click();
};
