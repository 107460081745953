import React from 'react';

import lightEmergency from './icons/light-emergency-on.svg';
import rotateExclamation from './icons/rotate-exclamation-2.svg';
import diamondExclamation from './icons/diamond-exclamation-2.svg';
import engineWarning from './icons/engine-warning.svg';
import triangleWarning from './icons/triangle-warning.svg';
import doctor from './icons/doctor.svg';
import itAlt from './icons/it-alt.svg';
import signal from './icons/signal-stream.svg';
import vest from './icons/vest.svg';
import info from './icons/info.svg';
import lifeRing from './icons/life-ring.svg';
import userGear from './icons/user-gear.svg';
import timeTwentyFour from './icons/time-twenty-four.svg';
import wrenchAlt from './icons/wrench-alt.svg';
import phoneFlip from './icons/phone-flip.svg';
import truckBolt from './icons/truck-bolt.svg';
import circleBolt from './icons/circle-bolt.svg';
import user from './icons/user.svg';
import usersGear from './icons/users-gear.svg';
import shield from './icons/shield.svg';

export const EmergencyGroupIconMap: {[name: string]: string} = {
    user: user,
    lightEmergency: lightEmergency,
    rotateExclamation: rotateExclamation,
    diamondExclamation: diamondExclamation,
    engineWarning: engineWarning,
    triangleWarning: triangleWarning,
    doctor: doctor,
    itAlt: itAlt,
    signal: signal,
    vest: vest,
    info: info,
    lifeRing: lifeRing,
    userGear: userGear,
    timeTwentyFour: timeTwentyFour,
    wrenchAlt: wrenchAlt,
    phoneFlip: phoneFlip,
    truckBolt: truckBolt,
    circleBolt: circleBolt,
    usersGear: usersGear,
    shield: shield,
};

const Sizes = {
    small: 16,
    medium: 24,
};

export const EmergencyGroupIcon: React.FC<{
    iconName: string;
    size?: 'small' | 'medium';
    style?: React.CSSProperties;
}> = props => {
    const key =
        EmergencyGroupIconMap[props.iconName] || EmergencyGroupIconMap.user;
    const size = Sizes[props.size || 'medium'];

    return (
        <img
            src={key}
            style={{
                width: size,
                height: size,
                ...(props.style ?? {}),
            }}
        />
    );
};
