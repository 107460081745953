import React from 'react';
import {Input} from 'antd';
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    LockOutlined,
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

export const PasswordInput = (props: {
    value?: string;
    onChange?: (value: string) => void;
    visible?: boolean;
    setVisible?: (value: boolean) => void;
    disabled?: boolean;
    repeat?: boolean;
}) => {
    const {t} = useTranslation();

    // eslint-disable-next-line prefer-const
    let [passwordVisible, setPasswordVisible] = React.useState(false);
    passwordVisible = props.visible ?? passwordVisible;

    return (
        <Input.Password
            value={props.value}
            placeholder={
                props.repeat
                    ? t('translation:login.passwordRepeat')
                    : t('translation:login.password')
            }
            onChange={e => {
                props.onChange?.(e.target.value);
            }}
            size="large"
            prefix={<LockOutlined />}
            iconRender={visible => {
                if (props.repeat) {
                    return <></>;
                }
                return visible ? <EyeOutlined /> : <EyeInvisibleOutlined />;
            }}
            visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: () => {
                    setPasswordVisible(!passwordVisible);
                    props.setVisible?.(!passwordVisible);
                },
            }}
        />
    );
};
