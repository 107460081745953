import React, {CSSProperties, ReactNode} from 'react';
import {Form, Select, SelectProps} from 'antd';

interface HasId {
    id: string;
}

export interface ObjectSelectPropsBase<T extends HasId> {
    fetch: (search: string) => Promise<T[]>;
    renderItem: (item: T) => ReactNode;
    debounce?: number;
    includeItems?: T[];
    disabled?: boolean;
    style?: CSSProperties;

    showOnlyLabelWhenDisabled?: boolean;

    value?: any;
    onChange?: (value: any) => void;
}

export interface ObjectSelectPropsBaseSingle<T extends HasId>
    extends ObjectSelectPropsBase<T> {
    value?: string;
    onChange?: (value: string) => void;
    mode?: undefined;
}

export interface ObjectSelectPropsBaseMulti<T extends HasId>
    extends ObjectSelectPropsBase<T> {
    value?: string[];
    onChange?: (value: string[]) => void;
    mode: 'tags';
}

export type ObjectSelectProps<T extends HasId> =
    | ObjectSelectPropsBaseSingle<T>
    | ObjectSelectPropsBaseMulti<T>;

export const ObjectSelect = <T extends HasId>(props: ObjectSelectProps<T>) => {
    const timeout = React.useRef(0);
    const [data, setData] = React.useState<SelectProps['options']>([]);

    const onSearch = (searchStr: string) => {
        if (timeout.current) {
            window.clearTimeout(timeout.current);
        }

        timeout.current = window.setTimeout(() => {
            props.fetch(searchStr).then(d => {
                const data = (props.includeItems ?? []).concat(d).map(v => ({
                    value: v.id,
                    label: props.renderItem(v),
                }));
                setData(data);
            });
        }, props.debounce || 200);
    };

    React.useEffect(() => onSearch(''), []);

    const mode = props.mode;

    if (props.disabled && props.showOnlyLabelWhenDisabled) {
        const selected = (data || []).find(d => d.value == props.value);
        if (selected) {
            return <>{selected.label}</>;
        }
    }

    const onChange = (value: string[] | string) => {
        let values: string[] = [];
        if (typeof value === 'string') {
            values = [value];
        } else {
            values = value;
        }

        const uuidRegex =
            /^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/;
        const filteredValue = values.filter(v => uuidRegex.test(v));

        // match input type
        if (typeof value === 'string') {
            props.onChange?.(filteredValue[0] as any);
        } else {
            props.onChange?.(filteredValue as any);
        }
    };

    return (
        <Select
            style={props.style}
            disabled={props.disabled}
            value={props.value as any}
            onChange={onChange}
            showSearch={true}
            defaultActiveFirstOption={false}
            onSearch={onSearch}
            filterOption={false}
            mode={mode}
            options={data}
        />
    );
};
