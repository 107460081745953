import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import React, {MutableRefObject} from 'react';
import {ReceiverGroup} from '../../../model/ReceiverGroup';
import {Api} from '../../../api/endpoints';
import {
    EditableColumnType,
    EditableTable,
    EditableTableActions,
} from '../../../components/table/EditTable';
import {Alert} from '../../../model/Alert';
import {AlertIcon} from '../../alert/components/AlertIcon';
import {ReceiverGroupTag} from '../../../components/model/receiverGroupTag';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {AlertTemplate} from '../../../model/AlertTemplate';
import {useAlertEditModal} from '../useAlertEditModal';
import {parseDurationSpecAsMinutes} from '../../../components/input/DurationPicker';
import {Button} from 'antd';

export const TemplateTable = (props: {
    onShow?: (alert: AlertTemplate) => void;
}) => {
    const {t} = useTranslation();

    const errorHandler = useErrorHandling();

    const createAlert = useAlertEditModal();

    const [groups, setGroups] = React.useState<ReceiverGroup[]>([]);
    React.useEffect(() => {
        Api.receiverGroup.list().then(setGroups).catch(errorHandler);
    }, []);

    let columns: EditableColumnType<AlertTemplate>[] = [
        {
            sorter: false,
            dataIndex: ['document', 'icon'],
            key: 'icon',
            title: ' ',
            render: (_, record) => {
                return <AlertIcon iconName={record.icon || ''} />;
            },
            weight: 0.5,
        },
        {
            sorter: true,
            dataIndex: 'validDuration',
            key: 'validDuration',
            render: (_, record) => {
                const duration = parseDurationSpecAsMinutes(
                    record.validDuration,
                );
                const minutes = duration % 60;
                const hours = ((duration - minutes) / 60) % 24;
                const days = (duration - minutes - hours * 60) / (60 * 24);

                return (
                    <div style={{textAlign: 'right'}}>
                        {days > 0 && (
                            <span>{t('time.day', {count: days})} </span>
                        )}
                        {hours > 0 && (
                            <span>{t('time.hour', {count: hours})} </span>
                        )}
                        {minutes > 0 && (
                            <span>{t('time.minute', {count: minutes})} </span>
                        )}
                    </div>
                );
            },
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'templateName',
            key: 'templateName',
            weight: 3,
        },
        {
            sorter: true,
            dataIndex: 'level',
            key: 'level',
            render: value => t(`alert.levelName.${value}`),
            weight: 2,
        },
        {
            sorter: false,
            dataIndex: 'receiverGroups',
            key: 'receiverGroup',
            render: (groups?: ReceiverGroup[]) => {
                if (!groups) return '';

                groups.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

                return groups.map(r => <ReceiverGroupTag receiverGroup={r} />);
            },
            weight: 2,
        },
        {
            dataIndex: '',
            key: '',
            render: (_, record) => {
                return (
                    <Button
                        type={'primary'}
                        onClick={() => {
                            createAlert({template: record});
                        }}>
                        {t('translation:alert.createAlert')}
                    </Button>
                );
            },
            weight: 2,
        },
        {
            sorter: false,
            dataIndex: 'creatorId',
            key: 'creatorId',
            weight: 4,
            render: (_, record) => {
                if (!record.creator) return '';

                let name = `${record.creator.firstName} ${record.creator.lastName}`;
                if (record.creator.department)
                    name += `, ${record.creator.department}`;
                return name;
            },
        },
        {
            sorter: true,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: value => t('util.datetime', {date: value}),
            weight: 2,
        },
    ];

    columns = columns.map(col => {
        if (col.key == '') {
            return col;
        }
        col.title = col.title || t(`alert.${col.key}`);
        return col;
    });

    const searchbarItems: SearchBarItem[] = [
        {
            label: t('translation:alert.templateName'),
            value: 'inTemplateName',
        },
        {
            label: t('alert.receiverGroup'),
            value: 'inReceiverGroup',
            select: groups?.map(g => ({
                value: g.id,
                label: g.name,
            })),
        },
        {
            label: t('alert.level'),
            value: 'byLevel',
            select: [
                {value: 'warn', label: t('translation:alert.levelName.warn')},
                {value: 'info', label: t('translation:alert.levelName.info')},
                {
                    value: 'silent',
                    label: t('translation:alert.levelName.silent'),
                },
            ],
        },
    ];

    return (
        <EditableTable<AlertTemplate, AlertTemplate>
            columns={columns}
            searchbarItems={searchbarItems}
            itemName={count => t('translation:alert.template', {count})}
            find={params => Api.alertTemplate.find(100, params)}
            actionButton={{
                label: t('edit.edit'),
                action: record => {
                    props.onShow?.(record);
                },
            }}
            delete={item => Api.alertTemplate.delete(item.id)}
            newEmpty={() => {
                throw new Error('Method not implemented.');
            }}
        />
    );
};
