import {ImageDragger} from './ImageDragger';
import {Card, Form, Input, Select, Space} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import {AlertIcon, AlertIconMap} from './AlertIcon';
import {useTranslation} from 'react-i18next';

const IconOptions = Object.keys(AlertIconMap).map(k => ({
    label: (
        <AlertIcon
            iconName={k}
            style={{
                marginTop: 16,
                marginRight: 16,
                marginLeft: 16,
            }}
        />
    ),
    value: k,
}));

export const AlertEditCard = (props: {
    isTemplate?: boolean;
    extra?: React.ReactNode;
}) => {
    const {t} = useTranslation();

    return (
        <Card
            style={{
                flex: 1,
                boxShadow: '0 0 1rem rgba(0,0,0,0.1)',
                margin: 16,
            }}
            cover={
                <Form.Item name={'image'}>
                    <ImageDragger />
                </Form.Item>
            }>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'stretch',
                    padding: 16,
                }}>
                <div style={{flex: 1}}>
                    <Space.Compact style={{width: '100%', display: 'flex'}}>
                        <Form.Item
                            rules={[{required: true}]}
                            label={t('translation:alert.title')}
                            name={['document', 'title']}
                            style={{flexGrow: 1}}>
                            <Input autoCorrect={'on'} size={'large'} />
                        </Form.Item>
                        <Form.Item label={' '} name={['document', 'icon']}>
                            <Select size={'large'} options={IconOptions} />
                        </Form.Item>
                    </Space.Compact>
                    <Form.Item
                        rules={[{required: true}]}
                        name={['document', 'description']}
                        label={t('translation:alert.description')}>
                        <TextArea
                            rows={5}
                            autoCorrect={'on'}
                            style={{
                                backgroundColor: 'inherit',
                                color: 'inherit',
                                borderColor: 'rgba(120,120,120,0.5)',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['document', 'instructions']}
                        label={t('translation:alert.instructions')}>
                        <TextArea
                            rows={5}
                            autoCorrect={'on'}
                            style={{
                                backgroundColor: 'inherit',
                                color: 'inherit',
                                borderColor: 'rgba(120,120,120,0.5)',
                            }}
                        />
                    </Form.Item>
                    {props.extra}
                </div>
                {props.isTemplate && (
                    <div
                        style={{
                            width: 80,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <div
                            style={{
                                marginLeft: 40,
                                padding: 0,
                                position: 'relative',
                                fontSize: 60,
                                writingMode: 'vertical-lr',
                                textOrientation: 'mixed',
                                letterSpacing: 10,
                                color: 'rgba(0,0,0,0.25)',
                            }}>
                            Vorlage
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};
