import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import de from './translations/de/translation.json';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import deDE from 'antd/locale/de_DE';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import DayJsLocal from 'dayjs/locale/de';

export const AntLanguage = deDE;
const _DayJsLocal = DayJsLocal;

dayjs.locale('de');
dayjs.extend(localizedFormat);
dayjs.extend(QuarterOfYear);
dayjs.locale('de');

const resources = {
    de: {translation: de},
};

const currencyOptions = {
    style: 'currency',
    currency: 'EUR',
};

function customFormat(value: any, format?: string, lng?: string): string {
    if (format === 'date') return dayjs(value).format('L');
    if (format === 'datetime')
        return dayjs(value).format('L') + ', ' + dayjs(value).format('LT');
    if (format === 'dateShort') return dayjs(value).format('DD MMM');
    if (format === 'dateNameMonth') return dayjs(value).format('LL');
    if (format === 'time') return dayjs(value).format('LT');
    if (format === 'currency')
        return new Intl.NumberFormat(lng, currencyOptions).format(value / 100);
    if (format === 'currencyNoFraction')
        return new Intl.NumberFormat(lng, {
            ...currencyOptions,
            maximumFractionDigits: 0,
        }).format(value / 100);
    return value;
}

i18n.use(initReactI18next).init({
    resources,
    compatibilityJSON: 'v4',
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
        escapeValue: false,
        format: customFormat,
    },
});

export const translation = i18n;
