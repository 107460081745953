import {ColumnType, TableRowSelection} from 'antd/es/table/interface';
import {Searchbar, SearchBarItem} from './Searchbar';
import {PagedReader, SearchParams} from '../../api/endpoints';
import React, {MutableRefObject} from 'react';
import {EditableColumnType} from './EditTable';
import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../error/ErrorHandlerProvider';
import {
    Button,
    Dropdown,
    Pagination,
    Space,
    Table,
    TableProps,
    Typography,
} from 'antd';
import {deprecate} from 'node:util';

interface Model {
    id: string;
}

export interface SearchableTableActions<T> {
    setSelection: (data: T[]) => void;
    getSelection: () => T[];
    invalidate: () => void;
}

export interface SearchableTable<T> extends ColumnType<T> {
    weight?: number;
}

export interface ExtraAction<T> {
    label: string;
    action: (e: T) => void;
}

export interface SearchableTableProps<T> {
    searchbarItems?: SearchBarItem[];
    columns: EditableColumnType<T>[];

    pageSize?: number;
    onPageSizeChange?: (size: number) => void;

    find: (params: SearchParams) => PagedReader<T>;

    itemName: string;

    mode?: 'search' | 'show';
    extraSearchBar?: {
        left?: React.ReactNode;
        right?: React.ReactNode;
    };

    onAdd?: () => void;

    tableProps?: TableProps<T>;

    selectable?: boolean;
    onSelect?: (selected: T[]) => void;
    onUpdate?: (items: T[], count: number) => void;

    actionsRef?: MutableRefObject<SearchableTableActions<T> | undefined>;

    onEdit?: (item: T) => void;
    extraActions?: ExtraAction<T>[];
}

/**
 * @deprecated use EditTable instead
 */
export const SearchableTable = <T extends Model>(
    props: SearchableTableProps<T>,
) => {
    const {t} = useTranslation();
    const errorHandling = useErrorHandling();

    const pageSize = props.pageSize ?? 10;

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const [data, setData] = React.useState<T[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalItems, setTotalItems] = React.useState<number>(0);
    const [sorter, setSorter] =
        React.useState<{field: string; reverse: boolean}>();
    const [search, setSearch] = React.useState<{[v: string]: string}>({});

    const fetchData = () => {
        let sortField: string = sorter?.field ?? 'updatedAt';
        if (sortField.includes(',')) {
            sortField = sortField.split(',').pop() ?? '';
        }
        const page = props.find({
            sort: sortField,
            direction: sorter ? (sorter.reverse ? 'DESC' : 'ASC') : 'DESC',
            ...search,
        });
        page.setPageSize(pageSize);
        page.getPage(currentPage)
            .then(async data => {
                setData(data);
                const total = await page.getTotal();
                setTotalItems(total);
                props.onUpdate && props.onUpdate(data, total);
            })
            .catch(errorHandling);
    };

    React.useEffect(fetchData, [
        currentPage,
        pageSize,
        sorter,
        JSON.stringify(search),
    ]);

    React.useEffect(() => {
        props.onSelect &&
            props.onSelect(data.filter(d => selectedItems.indexOf(d.id) >= 0));
    }, [JSON.stringify(selectedItems), JSON.stringify(data)]);

    const totalWidth = props.columns
        .map(c => c.weight ?? 1)
        .reduce((a, b) => a + b, 0);

    const rowSelection: TableRowSelection<T> = {
        type: 'checkbox',
        selectedRowKeys: selectedItems,
        onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
            setSelectedItems(selectedRows.map(r => r.id));
        },
    };

    if (props.actionsRef) {
        props.actionsRef.current = {
            getSelection: () => data.filter(d => d.id in selectedItems),
            setSelection: (data: T[]) => setSelectedItems(data.map(d => d.id)),
            invalidate: fetchData,
        };
    }

    const columns = [...props.columns];
    if (props.onEdit) {
        columns.push({
            title: '',
            dataIndex: '',
            key: 'action',
            weight: 1.5,
            render: (_: string, record: T) => {
                const items = (props.extraActions ?? []).map(
                    (action, index) => ({
                        label: action.label,
                        key: index,
                    }),
                );
                const onClick = (e: {key: string}) =>
                    props.extraActions?.[parseInt(e.key)].action(record);

                if (items.length > 0) {
                    return (
                        <Dropdown.Button
                            menu={{items, onClick}}
                            onClick={() =>
                                props.onEdit && props.onEdit(record)
                            }>
                            {t('edit.edit')}
                        </Dropdown.Button>
                    );
                } else {
                    return (
                        <Button
                            onClick={() =>
                                props.onEdit && props.onEdit(record)
                            }>
                            {t('edit.edit')}
                        </Button>
                    );
                }
            },
        });
    }

    const mergedColumns: EditableColumnType<T>[] = columns.map(col => {
        return {
            ...col,
            width: ((col.weight ?? 1) / totalWidth) * 100 + '%',
        };
    });

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            {props.mode !== 'show' && (
                <div style={{width: '100%', display: 'flex'}}>
                    {props.extraSearchBar?.left}
                    <Searchbar
                        style={{flex: 1}}
                        onChange={map => {
                            setSearch(map);
                        }}
                        items={props.searchbarItems ?? []}
                    />
                    {props.extraSearchBar?.right}
                </div>
            )}
            <Table
                {...props.tableProps}
                rowKey={'id'}
                rowSelection={props.selectable ? rowSelection : undefined}
                onChange={(_p, _r, sorter) => {
                    if (!Array.isArray(sorter)) {
                        if (sorter.order == 'ascend') {
                            setSorter({
                                field: sorter.field + '',
                                reverse: false,
                            });
                        } else if (sorter.order == 'descend') {
                            setSorter({
                                field: sorter.field + '',
                                reverse: true,
                            });
                        } else {
                            setSorter(undefined);
                        }
                    }
                }}
                style={{width: '100%'}}
                columns={mergedColumns}
                dataSource={data}
                pagination={false}
            />
            <Space
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <Typography.Text style={{}}>
                    {t('edit.count', {
                        count: totalItems,
                    })}{' '}
                </Typography.Text>
                <Pagination
                    current={currentPage}
                    onChange={setCurrentPage}
                    showSizeChanger={props.onPageSizeChange != undefined}
                    onShowSizeChange={(_, size) => {
                        const offset = (currentPage - 1) * pageSize;
                        setCurrentPage(Math.floor(offset / size) + 1);
                        props.onPageSizeChange && props.onPageSizeChange(size);
                    }}
                    total={totalItems}
                    pageSize={pageSize}
                />
            </Space>
        </Space>
    );
};
