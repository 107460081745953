import {Card, Flex, theme} from 'antd';
import {useTranslation} from 'react-i18next';
import Icon from 'antd/es/icon';
import {CloseOutlined} from '@ant-design/icons';

export const CenterCardLayout = (props: {
    children: React.ReactNode;
    onClose?: () => void;
    size?: 'small' | 'medium';
}) => {
    const {t} = useTranslation();
    const {
        token: {colorBgLayout, colorPrimary},
    } = theme.useToken();

    const width = {
        small: 400,
        medium: 600,
    }[props.size ?? 'small'];

    return (
        <Flex
            align="center"
            justify="center"
            style={{height: '100%', backgroundColor: colorBgLayout}}>
            <Card
                title={
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <div>{t('translation:login.adminPanel')}</div>

                        {props.onClose && (
                            <CloseOutlined onClick={props.onClose} />
                        )}
                    </div>
                }
                style={{width: width}}>
                {props.children}
            </Card>
        </Flex>
    );
};
