import {ReceiverGroup} from '../../../model/ReceiverGroup';
import {Employee} from '../../../model/Employee';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EditableColumnType} from '../../../components/table/EditTable';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {
    SearchableTable,
    SearchableTableActions,
} from '../../../components/table/SearchableTable';
import {Api} from '../../../api/endpoints';
import {Hideable} from '../../../components/layout/Hideable';
import {
    Button,
    Dropdown,
    Form,
    Input,
    MenuProps,
    Popconfirm,
    Popover,
    Space,
    theme,
    Typography,
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    DownOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {useInvalidate} from '../../../components/invalidate/Invalidate';
import {AxiosError} from 'axios';

export const ReceiverGroupTab = (props: {
    receiverGroup: ReceiverGroup;
    isNew?: boolean;
}) => {
    const {
        token: {colorBgLayout},
    } = theme.useToken();

    const {t} = useTranslation();
    const handleError = useErrorHandling();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [editMode, setEditMode] = React.useState<boolean>(
        props.isNew ?? false,
    );

    const [pageSize, setPageSize] = React.useState<number>(10);

    const actionRefExisting = React.useRef<SearchableTableActions<Employee>>();
    const actionRefNew = React.useRef<SearchableTableActions<Employee>>();

    const [selectedExisting, setSelectedExisting] = React.useState<Employee[]>(
        [],
    );
    const [selectedNew, setSelectedNew] = React.useState<Employee[]>([]);

    const addToGroup = () => {
        (async () => {
            setLoading(true);
            try {
                const group = props.receiverGroup;
                for (const employee of selectedNew) {
                    await Api.employee.put(employee, [
                        ...employee.receiverGroups.map(g => g.receiverGroup),
                        group,
                    ]);
                }
            } catch (e: any) {
                handleError(e);
            }
            actionRefExisting.current?.invalidate();
            actionRefNew.current?.invalidate();
            actionRefExisting.current?.setSelection([]);
            actionRefNew.current?.setSelection([]);
            setLoading(false);
        })().then();
    };

    const removeFromGroup = () => {
        (async () => {
            setLoading(true);
            try {
                const group = props.receiverGroup;
                for (const employee of selectedExisting) {
                    await Api.employee.put(employee, [
                        ...employee.receiverGroups
                            .map(g => g.receiverGroup)
                            .filter(g => g.id != group.id),
                    ]);
                }
            } catch (e: any) {
                handleError(e);
            }
            actionRefExisting.current?.invalidate();
            actionRefNew.current?.invalidate();
            actionRefExisting.current?.setSelection([]);
            actionRefNew.current?.setSelection([]);
            setLoading(false);
        })().then();
    };

    let columns: EditableColumnType<Employee>[] = [
        {
            sorter: true,
            dataIndex: 'department',
            key: 'department',
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'firstName',
            key: 'firstName',
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'lastName',
            key: 'lastName',
            weight: 2,
        },
        {
            fixed: 'left',
            sorter: true,
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
            weight: 2,
        },
    ];
    columns = columns.map(col => {
        col.title = t(`employee.${col.key}`);
        return col;
    });

    const searchItems: SearchBarItem[] = columns.map(col => {
        return {
            value: 'in' + col.key,
            label: col.title as string,
        };
    });

    return (
        <div>
            <ShowTile receiverGroup={props.receiverGroup} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <div style={{flex: 1, padding: 16, gap: 16, paddingLeft: 0}}>
                    <Typography.Title
                        level={4}
                        style={{
                            marginTop: 0,
                            marginBottom: 24,
                        }}>
                        {t('translation:receiverGroup.members')}
                    </Typography.Title>
                    <SearchableTable<Employee>
                        actionsRef={actionRefExisting}
                        selectable={editMode}
                        onSelect={setSelectedExisting}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        extraSearchBar={{
                            left: (
                                <Hideable shown={editMode}>
                                    <Button
                                        loading={loading}
                                        type={'primary'}
                                        disabled={selectedExisting.length == 0}
                                        onClick={removeFromGroup}>
                                        {t('translation:edit.delete')} (
                                        {selectedExisting.length})
                                    </Button>
                                </Hideable>
                            ),
                            right: (
                                <Hideable shown={!editMode}>
                                    <Button
                                        type={'primary'}
                                        onClick={() => setEditMode(true)}>
                                        {t('translation:edit.edit')}
                                    </Button>
                                </Hideable>
                            ),
                        }}
                        searchbarItems={searchItems}
                        columns={columns}
                        find={params =>
                            Api.employee.find(100, {
                                byReceiverGroupId: props.receiverGroup.id,
                                ...params,
                            })
                        }
                        itemName={t('employee.item')}
                    />
                </div>
                <div
                    style={{
                        flex: editMode ? 1 : 0,
                        padding: editMode ? 16 : 0,
                        width: editMode ? '50%' : '0%',
                        maxHeight: editMode ? '100%' : '10px',
                        opacity: editMode ? 1 : 0,
                        transition: 'all 0.2s ease-in-out',
                        overflow: 'hidden',
                        backgroundColor: colorBgLayout,
                        borderRadius: '8px',
                        gap: 16,
                    }}>
                    <Typography.Title
                        level={4}
                        style={{
                            marginTop: 0,
                            marginBottom: 24,
                        }}>
                        {t('translation:receiverGroup.nonMembers')}
                    </Typography.Title>
                    <SearchableTable<Employee>
                        actionsRef={actionRefNew}
                        selectable={editMode}
                        onSelect={setSelectedNew}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        extraSearchBar={{
                            left: (
                                <Hideable shown={editMode}>
                                    <Button
                                        loading={loading}
                                        type={'primary'}
                                        disabled={selectedNew.length == 0}
                                        onClick={addToGroup}>
                                        {t('edit.add')} ({selectedNew.length})
                                    </Button>
                                </Hideable>
                            ),
                            right: (
                                <Hideable shown={editMode}>
                                    <Button
                                        icon={<CloseOutlined />}
                                        type={'primary'}
                                        onClick={() => setEditMode(false)}
                                    />
                                </Hideable>
                            ),
                        }}
                        searchbarItems={searchItems}
                        columns={columns}
                        find={params =>
                            Api.employee.find(100, {
                                notByReceiverGroupId: props.receiverGroup.id,
                                ...params,
                            })
                        }
                        itemName={t('employee.item')}
                    />
                </div>
            </div>
        </div>
    );
};

export const ShowTile = (props: {receiverGroup: ReceiverGroup}) => {
    const {t} = useTranslation();

    const handleError = useErrorHandling();
    const {invalidate} = useInvalidate();

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [name, setName] = React.useState(props.receiverGroup.name);
    const [loading, setLoading] = React.useState<boolean>(false);

    const deleteGroup = () => {
        Api.receiverGroup
            .delete(props.receiverGroup)
            .then(() => {
                invalidate();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
                setEditMode(false);
            });
    };

    const updateName = (name: string) => {
        setLoading(true);
        const group = {...props.receiverGroup, name: name};
        Api.receiverGroup
            .put(group)
            .then(() => {
                invalidate();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
                setEditMode(false);
            });
    };

    const menuItems: MenuProps['items'] = [
        {
            label: (
                <Popconfirm
                    description={t(
                        'translation:receiverGroup.reallyDelete',
                        props.receiverGroup,
                    )}
                    placement={'bottom'}
                    onConfirm={deleteGroup}
                    title={t('translation:edit.delete?')}>
                    {t('translation:edit.delete?')}
                </Popconfirm>
            ),
            key: 'delete',
            danger: true,
        },
    ];

    const menuProps: MenuProps = {
        items: menuItems,
    };

    if (!editMode) {
        return (
            <Typography.Title>
                {props.receiverGroup.name}{' '}
                <Button
                    icon={<EditOutlined />}
                    type={'text'}
                    onClick={() => setEditMode(true)}
                />
                <Dropdown menu={menuProps}>
                    <Button icon={<DownOutlined />} type={'text'} />
                </Dropdown>
            </Typography.Title>
        );
    } else {
        return (
            <Typography.Title level={3}>
                <Space.Compact
                    style={{
                        alignItems: 'stretch',
                        justifyContent: 'stretch',
                    }}>
                    <Input
                        disabled={loading}
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            width: '20rem',
                        }}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <Button
                        style={{
                            height: 'auto',
                        }}
                        loading={loading}
                        disabled={!name}
                        icon={<CheckOutlined />}
                        onClick={() => {
                            updateName(name);
                        }}
                    />
                    <Button
                        style={{
                            height: 'auto',
                        }}
                        icon={<CloseOutlined />}
                        onClick={() => {
                            setEditMode(false);
                            setName(props.receiverGroup.name);
                        }}
                    />
                </Space.Compact>
            </Typography.Title>
        );
    }
};
