import {CSVSyncRow} from '../../../model/core';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {Alert, Button, Select, Space, Table} from 'antd';
import {CsvSyncContentContainer} from './ContentContainer';
import {ArrowRightOutlined} from '@ant-design/icons';
import {TFunction} from 'i18next';

interface TargetFormatDesc {
    key: keyof CSVSyncRow;
    label: string;
    required?: boolean;
    unique?: boolean;
}

export const CsvStepMapCsv = (props: {
    csvContent: {[key: string]: string}[];
    onFinish: (content: CSVSyncRow[]) => void;
    onAbort: () => void;
    onBack: () => void;
}) => {
    const {t} = useTranslation();
    const targetFormat: TargetFormatDesc[] = [
        {
            key: 'employeeNumber',
            label: t('translation:employee.employeeNumber'),
            required: true,
            unique: true,
        },
        {
            key: 'firstName',
            label: t('translation:employee.firstName'),
            required: true,
        },
        {
            key: 'lastName',
            label: t('translation:employee.lastName'),
            required: true,
        },
        {
            key: 'email',
            label: t('translation:employee.email'),
            required: true,
            unique: true,
        },
        {
            key: 'department',
            label: t('translation:employee.department'),
            required: false,
        },
        {
            key: 'phone',
            label: t('translation:employee.phone'),
            required: false,
        },
    ];

    const sourceKeys = props.csvContent[0]
        ? Object.keys(props.csvContent[0])
        : [];

    // target -> source
    const defaultMapping: [string, string][] = targetFormat.map((t, i) => {
        return [t.key, sourceKeys[i] || sourceKeys[0]];
    });

    const [mapping, setMapping] =
        React.useState<[string, string][]>(defaultMapping);

    const mappedKeys = props.csvContent.map(row => {
        const newRow: {[key: string]: string} = {};
        for (const kv of mapping) {
            newRow[kv[0]] = row[kv[1]];
        }
        return newRow;
    }) as any as CSVSyncRow[];

    const errors = checkForErrors(mappedKeys, targetFormat, t);

    const columns = targetFormat.map(col => ({
        title: (
            <MapCsvSelector
                required={col.required || false}
                defaultValue={
                    (mapping.find(kv => kv[0] == col.key) || ['', ''])[1]
                }
                sourceKeys={sourceKeys}
                destination={col.key}
                updateFn={(from, to) => {
                    const newMapping = mapping.filter(kv => kv[0] != to);
                    newMapping.push([to, from]);
                    setMapping(newMapping);
                }}>
                {col.label}
            </MapCsvSelector>
        ),
        dataIndex: col.key,
        key: col.key,
    }));

    return (
        <Space style={{width: '100%'}} direction={'vertical'}>
            <CsvSyncContentContainer>
                <Space style={{width: '100%'}} direction={'vertical'}>
                    {errors && <Alert type={'error'} message={errors} />}
                    <Table dataSource={mappedKeys} columns={columns} />
                </Space>
            </CsvSyncContentContainer>
            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={props.onAbort}>{t('edit.abort')}</Button>
                <Button onClick={props.onBack}>
                    {t('translation:edit.back')}
                </Button>
                <Button
                    disabled={!!errors}
                    type={'primary'}
                    onClick={() => props.onFinish(mappedKeys)}>
                    {t('edit.check')}
                </Button>
            </Space>
        </Space>
    );
};

const MapCsvSelector = (props: {
    defaultValue?: string;
    sourceKeys: string[];
    children: React.ReactNode;
    destination: string;
    updateFn: (from: string, to: string) => void;
    required: boolean;
}) => {
    const options = props.sourceKeys.map(value => ({value, label: value}));
    if (!props.required) {
        options.unshift({value: '', label: '-'});
    }
    return (
        <Space
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'stretch',
            }}>
            <Select
                style={{
                    width: '100%',
                }}
                defaultValue={props.defaultValue}
                onChange={value => props.updateFn(value, props.destination)}
                options={options}
            />
            <div style={{textAlign: 'center'}}>
                {' '}
                <ArrowRightOutlined size={9} />
            </div>
            <div style={{textAlign: 'center'}}>{props.children}</div>
        </Space>
    );
};

function checkForErrors(
    mappedKeys: CSVSyncRow[],
    targets: TargetFormatDesc[],
    t: TFunction,
): string | null {
    for (const target of targets) {
        if (target.required) {
            const noValue = mappedKeys.find(k => !k[target.key]);
            if (noValue) {
                return t('translation:csvSync.step.mapping.errorNoValue', {
                    ...noValue,
                    field: target.label,
                });
            }
        }

        if (target.unique) {
            const keys = mappedKeys.map(k => k[target.key]);
            const dupValue = keys.find((k, i) => keys.indexOf(k) != i);

            if (dupValue) {
                return t('translation:csvSync.step.mapping.errorNoUnique', {
                    value: dupValue,
                    field: target.label,
                });
            }
        }
    }

    return null;
}
