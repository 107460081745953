import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {
    EditableColumnType,
    EditableTable,
} from '../../../components/table/EditTable';
import {ErrorPopup} from '../../../components/table/EditableCell';
import {Employee} from '../../../model/Employee';
import {
    ManagementUser,
    ManagementUserStatus as CreateManagementUser,
    ManagementUserStatus,
    PermissionGroup,
} from '../../../model/ManagementUser';
import {Regex} from '../../../common/regex';
import {SearchBarItem} from '../../../components/table/Searchbar';
import React, {MutableRefObject} from 'react';
import {emptyId} from '../../../model/common';
import {Api} from '../../../api/endpoints';
import {EmployeeObjectSelect} from '../../employee/component/EmployeeObjectSelect';
import {App, Select} from 'antd';

export const EditManagerDataTable = () => {
    const {t} = useTranslation();
    const handleError = useErrorHandling();
    const {notification} = App.useApp();

    const columns: EditableColumnType<ManagementUser>[] = [
        {
            fixed: 'left',
            sorter: true,
            sorterKey: 'employeeNumber',
            editable: false,
            dataIndex: ['employee', 'employeeNumber'],
            title: t('employee.employeeNumberShort'),
            key: 'employeeNumber',
            weight: 1.5,
        },
        {
            sorter: true,
            editable: {
                content: record => {
                    if (record.employee) {
                        return (
                            <>
                                {record.employee.firstName}{' '}
                                {record.employee.lastName}
                            </>
                        );
                    } else {
                        return <EmployeeObjectSelect />;
                    }
                },
                rules: [
                    {
                        required: true,
                        message: (
                            <ErrorPopup
                                error={t('translation:edit.errorRequired', {
                                    field: t('employee.name'),
                                })}
                            />
                        ),
                    },
                ],
            },
            required: true,
            title: t('employee.name'),
            dataIndex: 'employeeId',
            sorterKey: 'lastName',
            key: 'employeeId',
            render: (_, mgr) => {
                return (
                    <>
                        {mgr.employee?.firstName} {mgr.employee?.lastName}{' '}
                    </>
                );
            },
            weight: 4,
        },
        {
            sorter: true,
            editable: false,
            title: t('employee.department'),
            dataIndex: ['employee', 'department'],
            sorterKey: 'department',
            key: 'department',
            weight: 2,
        },
        {
            sorter: true,
            editable: {
                content: record => {
                    return (
                        <Select
                            options={[
                                {
                                    value: 'ALL',
                                    label: t(
                                        'translation:managementUser.permissionGroup.ALL',
                                    ),
                                },
                            ]}
                        />
                    );
                },
            },
            title: t('managementUser.permissionGroup.item'),
            dataIndex: 'permissionGroup',
            key: 'permissionGroup',
            render: value => t('managementUser.permissionGroup.' + value),
            weight: 2,
        },
        {
            sorter: true,
            editable: {
                content: record => {
                    if (!record.employee) {
                        return t('translation:managementUser.status.INVITED');
                    }
                    return (
                        <Select
                            options={[
                                {
                                    value: 'ACTIVE',
                                    label: t(
                                        'translation:managementUser.status.ACTIVE',
                                    ),
                                },
                                {
                                    value: 'BLOCKED',
                                    label: t(
                                        'translation:managementUser.status.BLOCKED',
                                    ),
                                },
                            ]}
                        />
                    );
                },
            },
            title: t('managementUser.status.item'),
            dataIndex: 'status',
            key: 'status',
            render: value => t('managementUser.status.' + value),
            weight: 2,
        },
        {
            sorter: true,
            editable: false,
            title: t('employee.email'),
            dataIndex: ['employee', 'email'],
            sorterKey: 'email',
            key: 'email',
            weight: 4,
        },
        {
            sorter: true,
            title: t('translation:employee.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
        {
            sorter: true,
            title: t('translation:employee.updatedAt'),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
    ];

    const searchItems: SearchBarItem[] = [
        {
            value: 'inDepartment',
            label: t('employee.department'),
        },
        {
            value: 'inEmployeeNumber',
            label: t('employee.employeeNumberShort'),
        },
        {
            value: 'inName',
            label: t('employee.name'),
        },
        {
            value: 'inEmail',
            label: t('employee.email'),
        },
        {
            value: 'byStatus',
            label: t('managementUser.status.item'),
            select: [
                {
                    value: 'ACTIVE',
                    label: t('translation:managementUser.status.ACTIVE'),
                },
                {
                    value: 'INVITED',
                    label: t('translation:managementUser.status.INVITED'),
                },
                {
                    value: 'BLOCKED',
                    label: t('translation:managementUser.status.BLOCKED'),
                },
            ],
        },
    ];

    return (
        <EditableTable<ManagementUser, ManagementUser>
            searchbarItems={searchItems}
            columns={columns}
            newEmpty={() => ({
                id: emptyId(),
                employeeId: '',
                permissionGroup: PermissionGroup.ALL,
                status: ManagementUserStatus.INVITED,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
            })}
            find={params => Api.mangementUser.find(100, params)}
            itemName={count => t('managementUser.item', {count})}
            create={async (data: ManagementUser) => {
                const result = await Api.mangementUser.post(data);

                notification.success({
                    message: t(
                        'managementUser.invitationSent',
                        result.employee,
                    ),
                });
            }}
            update={async (data: ManagementUser) => {
                await Api.mangementUser.put(data, data);
            }}
            delete={async (data: ManagementUser) => {
                await Api.mangementUser.delete(data);
            }}
            extraActions={[
                {
                    label: t('employee.reinvite'),
                    action: (selected: ManagementUser) => {
                        Api.mangementUser
                            .reinvite(selected.id)
                            .then(() => {
                                notification.success({
                                    message: t('employee.reinviteSuccess'),
                                });
                            })
                            .catch(handleError);
                    },
                },
            ]}
        />
    );
};
