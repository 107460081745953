import {Button, Input, Space, Typography} from 'antd';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const {Title} = Typography;
export const DummyPage = (props: {name: string; functionDesc?: string}) => {
    const navigation = useNavigate();

    useEffect(() => {
        const t = setTimeout(() => {
            navigation('/alert/');
        }, 5000);

        return () => {
            clearTimeout(t);
        };
    });

    return (
        <Space
            direction={'vertical'}
            style={{
                width: '100%',
                height: '75%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Title level={3} style={{textAlign: 'center'}}>
                Derzeit erweitern wir {props.functionDesc || 'diese Funktion'}{' '}
                <br />- bitte kommen Sie zu einem späteren Zeitpunkt hierher
                zurück.
            </Title>
            <Title level={4}></Title>
        </Space>
    );
};
