import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {
    EditableColumnType,
    EditableTable,
} from '../../../components/table/EditTable';
import {EmergencyContactGroup} from '../../../model/EmergencyContactGroup';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {emptyId} from '../../../model/common';
import {Api} from '../../../api/endpoints';
import React, {MutableRefObject} from 'react';
import {EmergencyGroupIcon, EmergencyGroupIconMap} from './EmergencyGroupIcon';
import {Select} from 'antd';
import {
    CheckOutlined,
    DownOutlined,
    StopOutlined,
    UpOutlined,
} from '@ant-design/icons';
import {Color} from '../../../common/Color';
import {useInvalidate} from '../../../components/invalidate/Invalidate';

export const EmergencyContactGroupEditTable = () => {
    const {t} = useTranslation();
    const handleError = useErrorHandling();
    const {invalidate} = useInvalidate();

    const IconOptions = Object.keys(EmergencyGroupIconMap).map(k => ({
        label: (
            <EmergencyGroupIcon
                iconName={k}
                style={{
                    marginTop: 8,
                    marginRight: 8,
                    marginLeft: 8,
                }}
            />
        ),
        value: k,
    }));

    const onChangeOrder = (group: EmergencyContactGroup, edit: number) => {
        Api.emergencyContactGroup
            .update(group, {
                ...group,
                order: group.order + edit,
            })
            .then(invalidate)
            .catch(handleError);
    };

    const columns: EditableColumnType<EmergencyContactGroup>[] = [
        {
            required: true,
            dataIndex: 'icon',
            title: t('emergencyContactGroup.icon'),
            render: (iconName, item) => (
                <span
                    style={{
                        display: 'flex',
                        gap: '1rem',
                    }}>
                    <UpOutlined onClick={() => onChangeOrder(item, -1.5)} />
                    <EmergencyGroupIcon iconName={iconName} />
                    <DownOutlined onClick={() => onChangeOrder(item, +1.5)} />
                </span>
            ),
            key: 'icon',
            weight: 2,
            editable: {
                content: () => (
                    <Select
                        style={{
                            maxWidth: '6rem',
                        }}
                        options={IconOptions}
                    />
                ),
            },
        },
        {
            editable: true,
            required: true,
            dataIndex: 'name',
            title: t('emergencyContactGroup.name'),
            key: 'name',
            weight: 8,
        },
        {
            editable: false,
            dataIndex: 'order',
            title: t('emergencyContactGroup.order'),
            key: 'order',
            weight: 3,
        },
        {
            required: true,
            dataIndex: 'active',
            title: t('emergencyContactGroup.active'),
            key: 'active',
            weight: 4,
            render: (active: boolean) =>
                active ? (
                    <CheckOutlined style={{color: Color.PrimaryRed}} />
                ) : (
                    <StopOutlined />
                ),
            editable: {
                content: () => {
                    return (
                        <Select
                            style={{
                                width: '4rem',
                            }}
                            options={[
                                {value: true, label: <CheckOutlined />},
                                {value: false, label: <StopOutlined />},
                            ]}
                        />
                    );
                },
            },
        },
    ];

    const searchItems: SearchBarItem[] = [
        {
            value: 'inName',
            label: t('emergencyContactGroup.name'),
        },
    ];

    return (
        <EditableTable<EmergencyContactGroup, EmergencyContactGroup>
            searchbarItems={searchItems}
            columns={columns}
            defaultSort={'order'}
            defaultSortDirection={'ASC'}
            newEmpty={() => ({
                id: emptyId(),
                createdAt: '',
                updatedAt: '',
                name: '',
                icon: 'user',
                order: 0,
            })}
            find={params => Api.emergencyContactGroup.find(100, params)}
            create={async (e: EmergencyContactGroup) => {
                await Api.emergencyContactGroup.create(e);
            }}
            update={async (e: EmergencyContactGroup) => {
                await Api.emergencyContactGroup.update(e, e);
            }}
            delete={async (e: EmergencyContactGroup) => {
                await Api.emergencyContactGroup.delete(e);
            }}
            itemName={count => t('emergencyContactGroup.item', {count})}
        />
    );
};
