import React from 'react';

export interface HideableProps {
    shown?: boolean;
    duration?: number;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const Hideable: React.FC<HideableProps> = (props) => {
    const duration = props.duration || 0.2;
    const [rendered, setRendered] = React.useState(props.shown);
    const timeout = React.useRef<number | undefined>(undefined);
    const divRef = React.useRef<HTMLDivElement>(null);
    const width = React.useRef<number | undefined>(undefined);

    if (divRef.current) {
        width.current = Math.max(divRef.current.clientWidth, width.current || 0);
    }

    React.useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        if (props.shown) {
            setRendered(true);
        } else {
            timeout.current = setTimeout(() => setRendered(false), (props.duration || duration) * 1000) as unknown as number;
        }
    }, [props.shown]);


    if (!width.current) {
        return <div ref={divRef} style={{opacity: 0, ...props.style}}>
            {props.children}
        </div>;
    }

    return <div ref={divRef} style={{
        overflow: 'hidden',
        transitionDuration: `${duration}s`,
        transitionTimingFunction: 'ease-in-out',
        transitionProperty: 'all',
        ...(props.style || {}),
        ...(props.shown ? {
            width: width.current ? `${width.current}px` : 'auto',
        } : {
            width: '0rem',
            padding: '0rem',
            margin: '0rem',
        })
    }}>
        {props.children}
    </div>;
};