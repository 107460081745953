import React from 'react';
import {theme} from 'antd';

export const CsvSyncContentContainer = (
    props: {
        children: React.ReactNode;
    } & React.HTMLAttributes<HTMLDivElement>,
) => {
    const {token} = theme.useToken();

    return (
        <div
            style={{
                backgroundColor: token.colorBgLayout,
                padding: '1rem',
                borderRadius: '0.5rem',
            }}>
            {props.children}
        </div>
    );
};
