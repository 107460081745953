import {Button, Card, Typography} from 'antd';
import NewNotificationSvg from './undraw_new_notifications.svg';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface AddGroupCardProps {
    large?: boolean;
    onAction?: () => void;
}

export const AddGroupCard = (props: AddGroupCardProps) => {
    const {t} = useTranslation();

    return (
        <Card
            title={t('receiverGroup.card.title')}
            style={{width: props.large ? '30rem' : '40rem'}}
            cover={props.large && <img src={NewNotificationSvg} />}>
            <Typography.Paragraph>
                {t('translation:receiverGroup.card.description')}
            </Typography.Paragraph>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                <Button
                    type={props.large ? 'primary' : 'default'}
                    style={{marginRight: '2rem'}}
                    onClick={props.onAction}>
                    {t('translation:receiverGroup.card.action')}
                </Button>
            </div>
        </Card>
    );
};
