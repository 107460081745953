import {CenterCardLayout} from './component/CenterCardLayout';
import {
    Alert,
    App,
    Button,
    Form,
    Input,
    InputRef,
    Space,
    Spin,
    Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PhoneOutlined, TabletOutlined, UserOutlined} from '@ant-design/icons';
import {
    formatAxiosError,
    useErrorHandling,
} from '../../components/error/ErrorHandlerProvider';
import {Api} from '../../api/endpoints';
import {PasswordInput} from './component/PasswordInput';
import {useAuth} from '../../auth/AuthProvider';

type State =
    | LoadingState
    | EnterPhoneState
    | EnterCodeState
    | EnterPasswordState;

type EnterPhoneState = {kind: 'enter-phone'; phone: string};
type EnterCodeState = {
    kind: 'enter-code';
    claim: string;
    codeLength: number;
    code: string;
};
type EnterPasswordState = {
    kind: 'enter-password';
    claim: string;
};
type LoadingState = {kind: 'loading'};

export const ResetPasswordSMS = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandling();

    const [state, setState] = React.useState<State>({
        kind: 'enter-phone',
        phone: '',
    });
    const [error, setError] = React.useState<any | undefined>();

    return (
        <CenterCardLayout onClose={() => navigate('/')}>
            <Typography.Title level={4} style={{marginTop: 8}}>
                {t('resetPasswordSMS.heading')}
            </Typography.Title>
            {error && (
                <Form.Item>
                    <Alert message={formatAxiosError(error)} type="error" />{' '}
                </Form.Item>
            )}
            {state.kind === 'loading' && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10rem',
                    }}>
                    <Spin tip="..." size="large"></Spin>
                </div>
            )}
            {state.kind === 'enter-phone' && (
                <EnterPhone {...{state, setState, setError}} />
            )}
            {state.kind === 'enter-code' && (
                <EnterCode {...{state, setState, setError}} />
            )}
            {state.kind === 'enter-password' && (
                <EnterNewPassword {...{state, setState, setError}} />
            )}
        </CenterCardLayout>
    );
};

const EnterPhone: React.FC<{
    state: EnterPhoneState;
    setState: (state: State) => void;
    setError: (error: any) => void;
}> = ({state, setState, setError}) => {
    const {t} = useTranslation();

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Paragraph>
                {t('resetPasswordSMS.body')}
            </Typography.Paragraph>
            <Form>
                <Form.Item>
                    <Input
                        placeholder={t('resetPasswordSMS.phone')}
                        size="large"
                        prefix={<TabletOutlined />}
                        type="phone"
                        value={state.phone}
                        onChange={e =>
                            setState({
                                ...state,
                                phone: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        disabled={!state.phone}
                        onClick={() => {
                            setError(null);
                            setState({kind: 'loading'});
                            Api.passwordSMS
                                .send(state.phone)
                                .then(res =>
                                    setState({
                                        kind: 'enter-code',
                                        code: '',
                                        ...res,
                                    }),
                                )
                                .catch(e => {
                                    setError(e);
                                    setState({
                                        kind: 'enter-phone',
                                        phone: '',
                                    });
                                });
                        }}
                        type="primary"
                        style={{width: '100%'}}>
                        {t('translation:resetPasswordSMS.sendSMS')}
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    );
};

const EnterCode: React.FC<{
    state: EnterCodeState;
    setState: (state: State) => void;
    setError: (error: any) => void;
}> = ({state, setState, setError}) => {
    const {t} = useTranslation();

    const inputRefs = React.useRef<(InputRef | null)[]>(
        new Array(state.codeLength).fill(null),
    );

    useEffect(() => {
        // KISS
        const cancel = setTimeout(() => {
            inputRefs.current[0]?.focus();
        }, 100);
        return () => {
            clearTimeout(cancel);
        };
    }, []);

    return (
        <Space direction={'vertical'} style={{width: '100%'}} size={'large'}>
            <Typography.Paragraph>
                {t('resetPasswordSMS.sent.body')}
            </Typography.Paragraph>
            <Space style={{justifyContent: 'center', width: '100%'}}>
                {inputRefs.current.map((_, i) => (
                    <Input
                        ref={ref => {
                            inputRefs.current[i] = ref;
                        }}
                        value={state.code[i] || ''}
                        onKeyDown={e => {
                            console.log(e.key, i, state.code.length);
                            if (e.key === 'Backspace' && i > 0) {
                                inputRefs.current[i - 1]?.focus();
                            }
                        }}
                        onChange={e => {
                            const code = state.code.split('');
                            code[i] = e.target.value;
                            let fullCode = code.join('');
                            inputRefs.current[fullCode.length]?.focus();

                            if (fullCode.length > state.codeLength) {
                                fullCode = fullCode.slice(0, state.codeLength);
                            }

                            setState({
                                ...state,
                                code: fullCode,
                            });
                        }}
                        tabIndex={i}
                        size={'large'}
                        style={{
                            maxWidth: '3rem',
                            textAlign: 'center',
                        }}
                    />
                ))}
            </Space>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Button
                    disabled={state.code.length !== state.codeLength}
                    onClick={() => {
                        setError(null);
                        setState({kind: 'loading'});
                        Api.passwordSMS
                            .verify(state.claim, state.code)
                            .then(res =>
                                setState({
                                    kind: 'enter-password',
                                    ...res,
                                }),
                            )
                            .catch(e => {
                                setError(e);
                                setState({
                                    ...state,
                                    kind: 'enter-code',
                                    code: '',
                                });
                            });
                    }}
                    style={{
                        minWidth: '50%',
                    }}
                    type="primary">
                    {t('translation:resetPasswordSMS.verifyCode')}
                </Button>
            </div>
        </Space>
    );
};

interface ChangePasswordForm {
    newPassword: string;
    newPasswordRepeat: string;
}

const EnterNewPassword: React.FC<{
    state: EnterPasswordState;
    setState: (state: State) => void;
    setError: (error: any) => void;
}> = ({state, setState, setError}) => {
    const {t} = useTranslation();
    const errorHandling = useErrorHandling();
    const [passwordNewVisible, setPasswordNewVisible] = useState(false);
    const auth = useAuth();
    const {notification} = App.useApp();
    const navigate = useNavigate();

    const onFinish = (values: ChangePasswordForm) => {
        setState({kind: 'loading'});
        Api.passwordSMS
            .reset(state.claim, values.newPassword)
            .then(login => {
                notification.success({
                    message: t('passwordChange.success'),
                    placement: 'top',
                });
                auth.loginWithToken(login);
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            })
            .catch(e => {
                setState(state);
                errorHandling(e);
            });
    };

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Paragraph>
                {t('resetPasswordSMS.verified.body')}
            </Typography.Paragraph>
            <Form layout={'vertical'} onFinish={onFinish}>
                <Form.Item
                    name="newPassword"
                    label={t('passwordChange.newPassword')}
                    rules={[
                        {
                            required: true,
                            message: t(
                                'translation:passwordChange.passwordNewRequired',
                            ),
                        },
                        {
                            min: 8,
                            message: t(
                                'translation:passwordChange.passwordLengthError',
                                {n: 8},
                            ),
                        },
                    ]}>
                    <PasswordInput
                        visible={passwordNewVisible}
                        setVisible={setPasswordNewVisible}
                    />
                </Form.Item>
                <Form.Item
                    name="newPasswordRepeat"
                    rules={[
                        {
                            required: true,
                            message: t(
                                'translation:passwordChange.passwordNewRequired',
                            ),
                        },
                        formInstance => ({
                            message: t(
                                'translation:passwordChange.passwordRepeatError',
                            ),
                            validator: (_, value) => {
                                if (
                                    formInstance.getFieldValue(
                                        'newPassword',
                                    ) !== value
                                ) {
                                    return Promise.reject(new Error());
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}>
                    <PasswordInput visible={passwordNewVisible} repeat={true} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{width: '100%'}}>
                        {t('translation:edit.save')}
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    );
};
