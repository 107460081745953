import {
    ObjectSelect,
    ObjectSelectProps,
} from '../../../components/input/ObjectSelect';
import React from 'react';
import {Employee} from '../../../model/Employee';
import {Api} from '../../../api/endpoints';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {useTranslation} from 'react-i18next';

export interface EmployeeObjectSelectProps
    extends Omit<Omit<ObjectSelectProps<Employee>, 'fetch'>, 'renderItem'> {
    filter?: (e: Employee) => boolean;
}

export const EmployeeObjectSelect = (props: EmployeeObjectSelectProps) => {
    const errorHandling = useErrorHandling();
    const {t} = useTranslation();

    const fetchEmployees = async (search: string) => {
        try {
            const results = await Api.employee
                .find(100, {
                    mode: 'or',
                    inName: search,
                    inEmail: search,
                })
                .next();
            return results.filter(props.filter ?? (() => true));
        } catch (e: any) {
            errorHandling(e);
            return [];
        }
    };
    return (
        <ObjectSelect<Employee>
            fetch={fetchEmployees}
            renderItem={e => (
                <>
                    {e.firstName} {e.lastName}
                    {e.department && (
                        <small style={{opacity: 0.5}}>, {e.department}</small>
                    )}
                    {e.employeeNumber && (
                        <small style={{opacity: 0.5}}>
                            , P-Nr. {e.employeeNumber}
                        </small>
                    )}
                </>
            )}
            {...(props as any)}
        />
    );
};
