import {useTranslation} from 'react-i18next';
import {Alert, Button, Form, Space, theme, Typography} from 'antd';
import {CenterCardLayout} from './component/CenterCardLayout';
import {PasswordInput} from './component/PasswordInput';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {Api} from '../../api/endpoints';
import {useAuth} from '../../auth/AuthProvider';
import {AxiosError} from 'axios';

export const Register = (props: {kind: 'register' | 'reset'}) => {
    const code = window.location.hash.replace('#', '');
    const errorHandling = useErrorHandling();
    const auth = useAuth();

    const [errorMessage, setErrorMessage] = React.useState('');
    const navigate = useNavigate();

    const [password, setPassword] = React.useState('');
    const [passwordRepeat, setPasswordRepeat] = React.useState('');

    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const {t} = useTranslation();

    React.useEffect(() => {
        setErrorMessage('');
    }, [password, passwordRepeat]);

    if (!code) {
        navigate('/');
        return null;
    }

    if (auth.loggedIn === true) {
        auth.logout();
        return null;
    }

    const passwordReset = async () => {
        if (password != passwordRepeat) {
            setErrorMessage(t('translation:login.passwordRepeatError'));
            return;
        }

        const trimmedPassword = password.trim();
        if (trimmedPassword.length < 8) {
            setErrorMessage(t('translation:login.passwordLengthError', {n: 8}));
            return;
        }

        try {
            const result = await Api.register(code, trimmedPassword);
            auth.loginWithToken(result);
        } catch (e) {
            errorHandling(e as any);
            return;
        }

        navigate('/');
    };

    return (
        <CenterCardLayout
            size={'medium'}
            onClose={() => {
                navigate('/');
            }}>
            <Typography.Title level={4}>
                {t(`register.${props.kind}.heading`)}
            </Typography.Title>
            <Typography.Paragraph>
                {t(`register.${props.kind}.body`)}
            </Typography.Paragraph>
            <div style={{height: 8}} />
            <Form>
                {errorMessage && (
                    <Form.Item>
                        <Alert message={errorMessage} type="error" />{' '}
                    </Form.Item>
                )}
                <Form.Item>
                    <PasswordInput
                        value={password}
                        onChange={v => {
                            setErrorMessage('');
                            setPassword(v);
                        }}
                        visible={passwordVisible}
                        setVisible={setPasswordVisible}
                    />
                </Form.Item>
                <Form.Item>
                    <PasswordInput
                        value={passwordRepeat}
                        onChange={setPasswordRepeat}
                        repeat={true}
                        visible={passwordVisible}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        style={{width: '100%'}}
                        onClick={() => passwordReset().then()}>
                        {t(`register.${props.kind}.button`)}
                    </Button>
                </Form.Item>
            </Form>
        </CenterCardLayout>
    );
};
