import {Employee} from './Employee';
import {EmergencyContactGroup} from './EmergencyContactGroup';

export interface EmergencyContact {
    id: string;
    createdAt: string;
    updatedAt: string;

    role: string;
    active: boolean;
    public: boolean;

    employeeId: string;
    employee: Employee;
    contactData?: ContactData[];

    emergencyContactGroup: EmergencyContactGroup[];
}

export interface ContactData {
    kind: ContactDataKind;
    label: string;
    value: string;
}

export enum ContactDataKind {
    PHONE = 'phone',
    PLACE = 'place',
    EMAIL = 'email',
}

export interface CreateEmergencyContact {
    employeeId: string;
    role: string;
    active: boolean;
    public: boolean;
    emergencyContactGroupIds: string[];
    contactData: ContactData;
}
