import {useTranslation} from 'react-i18next';
import React from 'react';
import Dragger from 'antd/es/upload/Dragger';
import {PictureFilled} from '@ant-design/icons';
import {Typography} from 'antd';
import {RcFile} from 'antd/es/upload';

export const ImageSelector = (props: {
    value?: string;
    onChange?: (value: string) => void;
    style?: React.CSSProperties;
    width: number;
    aspectRatio: [number, number];
}) => {
    const {t} = useTranslation();
    const [image, setImage] = React.useState<string>();

    const onChange = (file: RcFile) => {
        setImage(undefined);

        resizeImage(
            file,
            props.width,
            (props.width * props.aspectRatio[1]) / props.aspectRatio[0],
        ).then(img => {
            console.log(img);
            setImage(img);
            props.onChange && props.onChange(img.split(',', 2)[1].trim()); // base64url to base64
        });

        return false;
    };
    return (
        <>
            <Dragger
                beforeUpload={onChange}
                accept={'image/*'}
                maxCount={1}
                style={props.style}>
                <div
                    style={{
                        marginTop: -16,
                        marginBottom: -16,
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        minHeight: '22rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    {image && (
                        <img
                            src={image}
                            style={{width: '100%'}}
                            alt={'Alert Cover Image'}
                        />
                    )}
                    {!image && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <PictureFilled
                                style={{
                                    fontSize: '5rem',
                                }}
                            />
                            <Typography.Text>
                                {t('edit.dragAndDropImage')}
                            </Typography.Text>
                        </div>
                    )}
                </div>
            </Dragger>
        </>
    );
};

export async function resizeImage(
    image: RcFile,
    width: number,
    height: number,
) {
    const blobUrl = URL.createObjectURL(image as any); // Yes, this is valid

    const img = new Image();
    img.src = blobUrl;
    await new Promise((resolve, reject) => {
        img.onload = () => resolve(true);
        img.onerror = e => reject(e);
    });

    const canvas = document.createElement('canvas');
    canvas.width = width;
    const scaledImageHeight = (width * img.height) / img.width;
    canvas.height = height;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(
        img,
        0,
        (canvas.height - scaledImageHeight) / 2,
        canvas.width,
        scaledImageHeight,
    );

    const jpg = canvas.toDataURL('image/png', 0.8);
    return jpg;
}
