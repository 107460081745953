import PageNotFoundImg from './undraw_page_not_found.svg';
import {Typography} from 'antd';
import {useTranslation} from 'react-i18next';

export const PageNotFound = () => {
    const {t} = useTranslation();

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div style={{maxWidth: '50rem',}}>
            <Typography.Title style={{
                position: 'relative',
                top: '5rem',
                left: '-20rem',
                fontSize: '3rem',
            }}>{t('error.404')}</Typography.Title>
            <img src={PageNotFoundImg} alt={'not found :/'}/>
        </div>
    </div>;
};