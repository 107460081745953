import {Alert} from '../../../model/Alert';
import {
    EditableColumnType,
    EditableTable,
    EditableTableActions,
} from '../../../components/table/EditTable';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {Api} from '../../../api/endpoints';
import React, {MutableRefObject} from 'react';
import {useTranslation} from 'react-i18next';
import {ReceiverGroup} from '../../../model/ReceiverGroup';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {ReceiverGroupTag} from '../../../components/model/receiverGroupTag';
import {Tag} from 'antd';
import {AlertIcon} from './AlertIcon';

export interface AlertsTableProps {
    showAlert?: (alert: Alert) => void;
    editAlert?: (alert: Alert) => void;
}

export const AlertsTable = (props: AlertsTableProps) => {
    const {t} = useTranslation();

    const errorHandler = useErrorHandling();

    const [groups, setGroups] = React.useState<ReceiverGroup[]>([]);
    React.useEffect(() => {
        Api.receiverGroup.list().then(setGroups).catch(errorHandler);
    }, []);

    let columns: EditableColumnType<Alert>[] = [
        {
            sorter: false,
            dataIndex: ['document', 'icon'],
            key: 'icon',
            title: ' ',
            render: (_, record) => {
                return <AlertIcon iconName={record.icon || ''} />;
            },
            weight: 0.5,
        },
        {
            sorter: true,
            dataIndex: 'validUntil',
            key: 'active',
            render: (_, record) => {
                const from = new Date(record.validFrom);
                const until = new Date(record.validUntil);
                const now = new Date();

                const active = from <= now && now <= until;
                if (active) {
                    return (
                        <Tag color="green">
                            {t('translation:alert.activeName.true')}
                        </Tag>
                    );
                } else {
                    return <Tag>{t('translation:alert.activeName.false')}</Tag>;
                }
            },
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'validFrom',
            key: 'validFrom',
            render: value => t('util.datetime', {date: value}),
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'validUntil',
            key: 'validUntil',
            render: value => t('util.datetime', {date: value}),
            weight: 2,
        },
        {
            sorter: true,
            dataIndex: 'title',
            key: 'title',
            weight: 4,
        },
        {
            sorter: true,
            dataIndex: 'level',
            key: 'level',
            render: value => t(`alert.levelName.${value}`),
            weight: 2,
        },
        {
            sorter: false,
            dataIndex: 'receiverGroups',
            key: 'receiverGroup',
            render: (groups?: ReceiverGroup[]) => {
                if (!groups) return '';

                groups.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

                return groups.map(r => <ReceiverGroupTag receiverGroup={r} />);
            },
            weight: 4,
        },
        {
            sorter: false,
            dataIndex: 'creatorId',
            key: 'creatorId',
            weight: 4,
            render: (_, record) => {
                if (!record.creator) return '';

                let name = `${record.creator.firstName} ${record.creator.lastName}`;
                if (record.creator.department)
                    name += `, ${record.creator.department}`;
                return name;
            },
        },
        {
            sorter: true,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: value => t('util.datetime', {date: value}),
            weight: 2,
        },
    ];
    columns = columns.map(col => {
        col.title = col.title || t(`alert.${col.key}`);
        return col;
    });

    const searchbarItems: SearchBarItem[] = [
        {
            label: t('alert.title'),
            value: 'inTitle',
        },
        {
            label: t('alert.receiverGroup'),
            value: 'inReceiverGroup',
            select: groups?.map(g => ({
                value: g.id,
                label: g.name,
            })),
        },
        {
            label: t('alert.level'),
            value: 'byLevel',
            select: [
                {value: 'warn', label: t('translation:alert.levelName.warn')},
                {value: 'info', label: t('translation:alert.levelName.info')},
                {
                    value: 'silent',
                    label: t('translation:alert.levelName.silent'),
                },
            ],
        },
    ];

    return (
        <EditableTable<Alert, Alert>
            columns={columns}
            searchbarItems={searchbarItems}
            itemName={count => t('alert.item', {count})}
            find={params => Api.alert.find(100, params)}
            actionButton={{
                label: t('edit.show'),
                action: record => {
                    props.showAlert && props.showAlert(record);
                },
            }}
            extraActions={[
                {
                    label: t('translation:edit.edit'),
                    action: record => {
                        props.editAlert && props.editAlert(record);
                    },
                },
            ]}
            newEmpty={() => {
                throw new Error('Method not implemented.');
            }}
        />
    );
};
