import {Card, Space, Typography} from 'antd';
import React from 'react';
import {AlertTemplate} from '../../model/AlertTemplate';
import {TemplateTable} from './components/TemplateTable';
import {useTranslation} from 'react-i18next';
import {TemplateCard} from './components/TemplateCard';
import {useTemplateEditModal} from './useTemplateEditModal';

export const TemplatePage = () => {
    const {t} = useTranslation();
    const alertTemplate = useTemplateEditModal();

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Title>
                {t('translation:alert.templates')}
            </Typography.Title>

            <TemplateCard
                style={{maxWidth: '40rem'}}
                onClick={() => {
                    alertTemplate({
                        alert: undefined,
                    });
                }}
            />

            <Card>
                <TemplateTable
                    onShow={(alert: AlertTemplate) => {
                        alertTemplate({
                            alert: alert,
                        });
                    }}
                />
            </Card>
        </Space>
    );
};
