import React, {createContext, useContext, useEffect, useRef} from 'react';
import {ErrorHandlerContextType} from '../error/ErrorHandlerProvider';

interface Props {
    onInvalidate?: () => void;
    propagate?: boolean;
    children: React.ReactNode;
}

export type InvalidateContextType = {
    invalidate: () => void;
    registerListener: (listener: () => void) => () => void;
    root: boolean;
};

const InvalidateContext = createContext<InvalidateContextType>({
    invalidate: () => {
        console.warn('invalidate nothing');
    },
    registerListener: () => {
        console.warn('register nothing');
        return () => {
            console.warn('unregister nothing');
        };
    },
    root: true,
});

export const useInvalidate = () => {
    return useContext(InvalidateContext);
};

export const useInvalidateListener = (listener: () => void) => {
    const {registerListener} = useInvalidate();

    useEffect(() => {
        return registerListener(listener);
    });
};

export const InvalidateReceiver = (props: Props) => {
    const upper = useInvalidate();
    const listeners = useRef<(() => void)[]>([]);

    const invalidate = () => {
        if (props.propagate !== false && !upper.root) {
            upper.invalidate();
        }
        props.onInvalidate?.();

        for (const listener of listeners.current) {
            listener();
        }
    };

    const registerListener = (listener: () => void) => {
        listeners.current.push(listener);
        return () => {
            listeners.current = listeners.current.filter(l => l !== listener);
        };
    };

    return (
        <InvalidateContext.Provider
            value={{invalidate, root: false, registerListener}}>
            {props.children}
        </InvalidateContext.Provider>
    );
};
