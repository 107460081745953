import {Alert, AlertDocument, AlertLevel} from '../../model/Alert';
import {Button, Checkbox, Form, Input, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {ReceiverSelection} from '../../components/input/ReceiverSelection';
import {AlertTemplate, UpdateAlertTemplate} from '../../model/AlertTemplate';
import {DurationPicker} from '../../components/input/DurationPicker';
import {AlertEditCard} from './components/AlertEditCard';
import {InputItemAlertLevel} from './components/InputItemAlertLevel';
import {InputItemEmergencyContactSelection} from './components/InputItemEmergencyContactSelection';
import {ImageDraggerValue} from './components/ImageDragger';
import {Api} from '../../api/endpoints';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {useAlertEditModal} from './useAlertEditModal';
import {useLargeModal} from './components/useLargeModal';
import {useInvalidate} from '../../components/invalidate/Invalidate';
import {AlertIconMap} from './components/AlertIcon';

interface UpdateAlertTemplateForm {
    templateName: string;
    document: AlertDocument;
    image: ImageDraggerValue;
    contactIds: string[];
    receiverGroupIds: string[];
    showDashboard: boolean;
    valid: string;
}

export const useTemplateEditModal = () => {
    return useLargeModal((close, args: {alert?: AlertTemplate}) => (
        <TemplateEditPage {...args} onFinish={close} />
    ));
};

const TemplateEditPage = (props: {
    onFinish: () => void;
    alert?: AlertTemplate;
}) => {
    const [form] = Form.useForm();

    const {t} = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [employeeCount, setEmployeeCount] = React.useState(0);
    const errorHandling = useErrorHandling();
    const invalidate = useInvalidate();

    const initialValues: UpdateAlertTemplateForm = {
        templateName: props.alert?.templateName ?? '',
        document: {
            level: props.alert?.level ?? AlertLevel.INFo,
            title: props.alert?.title ?? '',
            icon: props.alert?.icon ?? Object.keys(AlertIconMap)[0],
            description: props.alert?.description ?? '',
            instructions: props.alert?.instructions ?? '',
        },
        image: {
            blobId: props.alert?.imageBlobId,
        },
        receiverGroupIds: props.alert?.receiverGroups?.map(g => g.id) ?? [],
        contactIds: props.alert?.contacts.map(g => g.id) ?? [],
        valid: props.alert?.validDuration ?? '24h',
        showDashboard: props.alert?.showDashboard ?? false,
    };

    const onFinish = async (record: UpdateAlertTemplateForm) => {
        const args: UpdateAlertTemplate = {
            templateName: record.templateName || record.document.title,
            image: record.image.base64,
            contactIds: record.contactIds,
            receiverGroupIds: record.receiverGroupIds,
            validDuration: record.valid,
            document: record.document,
            showDashboard: record.showDashboard,
        };
        try {
            const result =
                props.alert && props.alert.id
                    ? await Api.alertTemplate.update(props.alert, args)
                    : await Api.alertTemplate.create(args);

            props.onFinish();
            invalidate.invalidate();
            return result;
        } catch (e) {
            errorHandling(e as any);
        }
    };

    const createAlert = useAlertEditModal();
    const errorHandler = useErrorHandling();

    const onShowAlert = async () => {
        setLoading(true);
        try {
            let id = props.alert?.id ?? '';
            if (form.isFieldsTouched()) {
                const res = await onFinish(form.getFieldsValue());
                id = res?.id ?? '';
            }

            if (!id) {
                errorHandler(new Error('No alert id'));
                return;
            }

            const item = await Api.alertTemplate.get(id);
            createAlert({
                template: item,
            });
            props.onFinish();
        } catch (e) {
            errorHandler(e as any);
        }
        setLoading(false);
    };

    return (
        <Form
            preserve={false}
            form={form}
            initialValues={initialValues}
            layout={'vertical'}
            onFinish={values => onFinish(values).then()}>
            <div style={{height: 32}} />
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'stretch',
                    gap: 32,
                }}>
                <AlertEditCard
                    isTemplate={true}
                    extra={
                        props.alert &&
                        props.alert.id && (
                            <Form.Item shouldUpdate={true}>
                                {() => (
                                    <Button
                                        onClick={onShowAlert}
                                        type={'primary'}
                                        style={{width: '100%'}}>
                                        {t('translation:alert.createAlert')}
                                        {form.isFieldsTouched() &&
                                            ' & ' + t('translation:edit.save')}
                                    </Button>
                                )}
                            </Form.Item>
                        )
                    }
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '40%',
                    }}>
                    <Typography.Title level={2}>
                        {props.alert && props.alert.id
                            ? t('alert.editTemplate')
                            : t('alert.createTemplate')}
                    </Typography.Title>

                    <Form.Item
                        label={t('translation:alert.templateName')}
                        shouldUpdate={true}>
                        {() => (
                            <>
                                {' '}
                                <Form.Item name={'templateName'} noStyle>
                                    <Input
                                        placeholder={form.getFieldValue([
                                            'document',
                                            'title',
                                        ])}
                                    />
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    name={'showDashboard'}
                                    valuePropName="checked">
                                    <Checkbox>
                                        {t('translation:alert.showDashboard')}
                                    </Checkbox>
                                </Form.Item>
                            </>
                        )}
                    </Form.Item>

                    <InputItemAlertLevel />

                    <Form.Item
                        label={t('translation:alert.receiverGroup')}
                        name={'receiverGroupIds'}>
                        <ReceiverSelection
                            setCount={setEmployeeCount}
                            setLoading={setLoading}
                        />
                    </Form.Item>
                    <Typography.Paragraph
                        type={'secondary'}
                        style={{marginTop: -16}}>
                        Bei einem Alarm würden <b>{employeeCount}</b>{' '}
                        Mitarbeiter benachrichtigt.
                    </Typography.Paragraph>

                    <Form.Item
                        label={t('translation:alert.valid')}
                        name={'valid'}>
                        <DurationPicker />
                    </Form.Item>
                    <Typography.Paragraph type={'secondary'}>
                        Die Gültigkeit der Alarmierung bestimmt, wie lange der
                        Alarm in der App sichtbar ist. Anschließend wird der
                        Alarm automatisch archiviert.
                    </Typography.Paragraph>
                    <InputItemEmergencyContactSelection />

                    <div style={{flex: 1}} />
                    <Space
                        style={{
                            marginTop: 16,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <Button onClick={props.onFinish}>
                            {t('translation:edit.abort')}
                        </Button>
                        <Button
                            type={'primary'}
                            onClick={form.submit}
                            loading={loading}>
                            {t('translation:edit.save')}
                        </Button>
                    </Space>
                </div>
            </div>
        </Form>
    );
};
