import {
    InvalidateReceiver,
    useInvalidate,
} from '../../components/invalidate/Invalidate';
import {
    Button,
    Card,
    Form,
    Input,
    Modal,
    Space,
    Tabs,
    theme,
    Typography,
} from 'antd';
import {CSVCard} from '../employee/component/CSVCard';
import {EditEmployeeDataTable} from '../employee/component/EditEmployeeTable';
import React from 'react';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Api} from '../../api/endpoints';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {ReceiverGroupTab} from './component/ReceiverGroupTab';
import NewNotificationSvg from './component/undraw_new_notifications.svg';
import {AddGroupCard} from './component/AddGroupCard';
import {PlusOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

interface ReceiverGroupPage {
    unused?: boolean;
}

export const ReceiverGroupPage = (props: ReceiverGroupPage) => {
    const {t} = useTranslation();

    const [groups, setGroups] = React.useState<ReceiverGroup[]>();
    const handleErrors = useErrorHandling();
    const [showCreateGroupModal, setShowCreateGroupModal] =
        React.useState(false);

    const [tabActiveKey, setTabActiveKey] = React.useState<string>();
    const [newTab, setNewTab] = React.useState('');

    const fetchData = () => {
        Api.receiverGroup
            .list()
            .then(groups => {
                setGroups(groups);
                const hasActiveKey = groups.find(i => i.id == tabActiveKey);
                if (!hasActiveKey) {
                    setTabActiveKey(
                        groups.length > 0 ? groups[0].id : undefined,
                    );
                }
            })
            .catch(handleErrors);
    };

    React.useEffect(fetchData, []);

    const items = (groups || []).map(group => {
        return {
            label: group.name || '???',
            key: group.id,
            children: (
                <ReceiverGroupTab
                    receiverGroup={group}
                    isNew={group.id == newTab}
                />
            ),
        };
    });

    return (
        <InvalidateReceiver
            onInvalidate={() => {
                fetchData();
            }}>
            <CreateGroupModal
                onCreate={id => {
                    setNewTab(id);
                    setTabActiveKey(id);
                }}
                show={showCreateGroupModal}
                onClose={() => setShowCreateGroupModal(false)}
            />
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                }}>
                <Typography.Title>
                    {t('translation:receiverGroup.title')}
                </Typography.Title>

                {groups && groups.length > 0 && (
                    <AddGroupCard
                        large={false}
                        onAction={() => setShowCreateGroupModal(true)}
                    />
                )}

                <Card>
                    <Tabs
                        onChange={key => {
                            setTabActiveKey(key);
                        }}
                        activeKey={tabActiveKey}
                        size={'large'}
                        tabPosition={'top'}
                        tabBarExtraContent={{
                            left: (
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        setShowCreateGroupModal(true)
                                    }
                                    type="dashed"
                                    style={{marginRight: '2rem'}}></Button>
                            ),
                        }}
                        items={items}
                    />
                    {groups && groups.length == 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                            }}>
                            <AddGroupCard
                                onAction={() => setShowCreateGroupModal(true)}
                                large={true}
                            />
                        </div>
                    )}
                </Card>
            </div>
        </InvalidateReceiver>
    );
};

const CreateGroupModal = (props: {
    show: boolean;
    onClose: () => void;
    onCreate: (id: string) => void;
}) => {
    const {t} = useTranslation();
    const {invalidate} = useInvalidate();

    const onFinish = (values: {name: string}) => {
        values.name = values.name.trim();
        props.onClose();
        Api.receiverGroup.post(values).then(ret => {
            invalidate();
            props.onCreate(ret.id);
        });
    };

    return (
        <Modal
            title={t('translation:receiverGroup.modal.title')}
            destroyOnClose={true}
            centered={true}
            onCancel={() => props.onClose()}
            footer={null}
            open={props.show}>
            <Form layout={'vertical'} onFinish={onFinish}>
                <Form.Item
                    name={'name'}
                    label={t('translation:receiverGroup.name')}
                    rules={[
                        {
                            required: true,
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    style={{
                        marginBottom: 0,
                    }}>
                    <Space style={{justifyContent: 'right', width: '100%'}}>
                        <Button onClick={props.onClose}>
                            {t('translation:edit.abort')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('translation:edit.save')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};
