import React, {createContext, useContext} from 'react';
import axios from 'axios';
import {Api} from '../api/endpoints';
import {ManagementUser} from '../model/ManagementUser';
import {LoginResult} from '../model/LoginResult';

export interface AuthContextType {
    user: ManagementUser | null;
    refetchUser: () => Promise<void>;
    loginWithToken: (res: LoginResult) => void;
    login: (mail: string, password: string) => Promise<AuthResult>;
    logout: () => void;
    loggedIn: boolean | null;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

interface Props {
    children: React.ReactNode;
}

export type AuthResult = {
    ok: boolean;
    message: undefined | 'CREDENTIALS' | 'ERROR';
};

const AuthContext = createContext<AuthContextType>({
    user: null,
    login: async (_username: string, _password: string) => {
        throw new Error('Not Implemented');
    },
    loginWithToken: () => {
        throw new Error('Not Implemented');
    },
    logout: () => null,
    loggedIn: false,
    refetchUser: async () => {
        42;
    },
});

export const AuthProvider: React.FC<Props> = props => {
    const [token, setToken] = React.useState(localStorage.getItem('token'));
    const [loggedIn, setLoggedIn] = React.useState<boolean>(!!token);
    const [user, setUser] = React.useState<ManagementUser | null>(null);

    React.useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            setLoggedIn(true);
        } else {
            localStorage.removeItem('token');
            delete axios.defaults.headers['Authorization'];
            setLoggedIn(false);
        }
    }, [token]);

    const fetchUser = async () => {
        if (!token) {
            setUser(null);
            return;
        }

        try {
            setUser(await Api.currentUser());
        } catch (e) {
            console.log(e);
            setLoggedIn(false);
            setUser(null);
        }
    };

    React.useEffect(() => {
        fetchUser().then();
    }, [token]);

    const login = async (
        mail: string,
        password: string,
    ): Promise<AuthResult> => {
        try {
            const {token, user} = await Api.login(mail, password);
            setToken(token);
            setUser(user);
            return {
                ok: true,
                message: undefined,
            };
        } catch (e: any) {
            setToken('');
            localStorage.removeItem('token');
            if (e.response) {
                return {
                    ok: false,
                    message: 'CREDENTIALS',
                };
            } else {
                return {
                    ok: false,
                    message: 'ERROR',
                };
            }
        }
    };
    const logout = () => {
        setToken(null);
        return null;
    };

    const loginWithToken = (res: LoginResult) => {
        setToken(res.token);
        setUser(res.user);
    };

    const value = {
        user,
        login,
        logout,
        loginWithToken,
        loggedIn,
        refetchUser: fetchUser,
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
};
