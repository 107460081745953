import {PresetColors} from 'antd/es/theme/internal';

// DOCS AT https://ant.design/theme-editor

export const Color = {
    BackgroundBlueGray: '#f1f4f8',
    White: '#ffffff',

    Gray: '#57636c',
    Black: '#101213',
    PrimaryRed: 'hsl(350, 96%, 43%)', // #D70427
    WarningOrange: '#d98b04',

    LinkBlue: 'rgba(18,126,250,0.63)',
    LinkBlueHover: 'rgba(18,126,250,0.83)',
    LinkBlueActive: 'rgba(18,126,250,0.93)',
};

// Doc are at:
// https://ant.design/theme-editor#

export const ThemeLight = {
    token: {
        colorPrimary: Color.PrimaryRed,
        colorInfo: Color.Black,
        colorWarning: Color.WarningOrange,
        colorError: Color.WarningOrange,
        colorTextBase: Color.Black,
        colorBgBase: Color.White,
        colorBgLayout: Color.BackgroundBlueGray,
        colorBgSpotlight: Color.Gray,
        //
        colorLink: Color.LinkBlue,
        colorLinkHover: Color.LinkBlueHover,
        colorLinkActive: Color.LinkBlueActive,
        linkDecoration: 'underline',
        linkHoverDecoration: 'underline',
        //
        Table: {
            rowSelectedBg: 'hsla(350, 96%, 43%, 0.07)',
            rowSelectedHoverBg: 'hsla(350, 96%, 43%, 0.10)',
        },
    },
};

export const ThemeDark = {
    token: {
        colorPrimary: Color.PrimaryRed,
        colorInfo: Color.Black,
        colorWarning: Color.WarningOrange,
        colorWarningBg: Color.WarningOrange,
        colorError: Color.WarningOrange,
        colorErrorBg: '#915d02',
        colorErrorBorder: Color.WarningOrange,
        colorTextBase: Color.White,
        colorBgBase: '#262b2f',
        colorBgLayout: '#181d1e',
        colorBgSpotlight: Color.BackgroundBlueGray,
        //
        colorLink: Color.Gray,
        colorLinkHover: Color.White,
        colorLinkActive: Color.White,
        linkDecoration: 'underline',
        linkHoverDecoration: 'underline',
        //
        Table: {
            rowSelectedBg: 'hsla(350, 96%, 43%, 0.07)',
            rowSelectedHoverBg: 'hsla(350, 96%, 43%, 0.10)',
        },
        Menu: {
            itemSelectedBg: Color.Gray,
            itemSelectedColor: Color.White,
            itemActiveBg: Color.Gray,
            itemActiveColor: Color.White,
        },
        Select: {
            optionSelectedBg: Color.Gray,
        },
        Button: {
            primaryShadow: 'none',
        },
    },
};

export function uuidToTagColor(uuid: string): string {
    let sum = 0;
    for (let i = 0; i < uuid.length; i++) {
        sum += uuid.charCodeAt(i);
    }
    return PresetColors[sum % PresetColors.length];
}
