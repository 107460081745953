import {Form} from 'antd';
import {ObjectSelect} from '../../../components/input/ObjectSelect';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Api} from '../../../api/endpoints';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';

export const InputItemEmergencyContactSelection = () => {
    const {t} = useTranslation();
    const handleError = useErrorHandling();

    const fetchEmergencyContacts = async (search: string) => {
        try {
            return await Api.emergencyContact
                .find(100, {
                    mode: 'or',
                    inName: search,
                    inEmail: search,
                })
                .next();
        } catch (e: any) {
            handleError(e);
            return [];
        }
    };

    return (
        <Form.Item label={t('translation:alert.contacts')} name={'contactIds'}>
            <ObjectSelect
                mode={'tags'}
                fetch={fetchEmergencyContacts}
                renderItem={e => (
                    <>
                        {e.employee.firstName} {e.employee.lastName} {', '}
                        <small>{e.role + ' '}</small>
                        {e.employee.department && (
                            <small>
                                {', '}
                                {e.employee.department}
                            </small>
                        )}
                    </>
                )}
            />
        </Form.Item>
    );
};
