import {RcFile} from 'antd/lib/upload';

export async function resizeImage(
    image: RcFile,
    width: number,
    height: number,
) {
    const blobUrl = URL.createObjectURL(image as any); // Yes, this is valid

    const img = new Image();
    img.src = blobUrl;
    await new Promise((resolve, reject) => {
        img.onload = () => resolve(true);
        img.onerror = e => reject(e);
    });

    const canvas = document.createElement('canvas');
    canvas.width = width;
    const scaledImageHeight = (width * img.height) / img.width;
    canvas.height = Math.min(height, scaledImageHeight);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(
        img,
        0,
        (canvas.height - scaledImageHeight) / 2,
        canvas.width,
        scaledImageHeight,
    );

    const jpg = canvas.toDataURL('image/jpeg', 0.8);
    return jpg;
}
