import {InvalidateReceiver} from '../../components/invalidate/Invalidate';
import {Card, Space, Typography} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EditManagerDataTable} from './component/EditManagerDataTable';

export const ManagerPage = () => {
    const {t} = useTranslation();

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Title>Administratoren</Typography.Title>

            <Card>
                <EditManagerDataTable />
            </Card>
        </Space>
    );
};
