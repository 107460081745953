import {EditOutlined} from '@ant-design/icons';
import React from 'react';

export const EditIconInput = <Item,>(props: {
    render?: (value?: Item) => React.ReactNode;
    children: React.ReactElement;

    onUnlock?: () => void;

    value?: Item;
    onChange?: (value: Item) => void;
}) => {
    const [locked, setLocked] = React.useState(true);

    React.useEffect(() => {
        if (!locked) {
            props.onUnlock && props.onUnlock();
        }
    }, [locked]);

    if (locked) {
        return (
            <>
                {props.render ? props.render(props.value) : props.value + ''}
                <span style={{width: 16}}> </span>
                <EditOutlined onClick={() => setLocked(false)} />
            </>
        );
    } else {
        return (
            <>
                <props.children.type
                    value={props.value}
                    onChange={props.onChange}
                    {...props.children.props}
                />
            </>
        );
    }
};
