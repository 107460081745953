import {Alert} from '../../model/Alert';
import {Api} from '../../api/endpoints';
import {
    Button,
    Collapse,
    Descriptions,
    Popconfirm,
    Space,
    Typography,
} from 'antd';
import {useTranslation} from 'react-i18next';
import {Color} from '../../common/Color';
import {ReceiverGroupTag} from '../../components/model/receiverGroupTag';
import React from 'react';
import {useAlertEditModal} from './useAlertEditModal';
import {useLargeModal} from './components/useLargeModal';
import {useInvalidate} from '../../components/invalidate/Invalidate';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';

export const useAlertShowModal = () => {
    const createAlert = useAlertEditModal();
    const invalidateTable = useInvalidate();

    return useLargeModal((close, args: {alert: Alert}) => (
        <AlertShowPage
            {...args}
            onEdit={alert => {
                close();
                createAlert({alert});
            }}
            onClose={() => {
                close();
                invalidateTable.invalidate();
            }}
        />
    ));
};

const AlertShowPage = (props: {
    alert: Alert;
    onEdit?: (alert: Alert) => void;
    onClose?: () => void;
}) => {
    const alert = props.alert;
    const {t} = useTranslation();
    const errorHandler = useErrorHandling();

    const isActive = new Date() <= new Date(alert.validUntil);
    const showUpdater =
        Math.abs(
            new Date(alert.createdAt).getTime() -
                new Date(alert.updatedAt).getTime(),
        ) > 1000; // Update and create time are the same => no update

    const deactivateAlert = () => {
        const alert = props.alert;
        Api.alert
            .update(alert, {
                document: alert,
                target: {
                    receiverGroupIds: alert.receiverGroups?.map(g => g.id),
                },
                contactIds: alert.contactIds,
                validFrom: alert.validFrom,
                image: '', // no update
                validUntil: new Date().toISOString(),
            })
            .catch(errorHandler)
            .then(() => {
                props.onClose?.();
            });
    };

    return (
        <div>
            {alert.imageBlobId ? (
                <img
                    src={Api.imageBlobUrl(alert.imageBlobId)}
                    style={{
                        width: '100%',
                    }}
                />
            ) : (
                <div style={{height: '2rem'}} />
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}>
                <Typography.Title level={2}>{alert.title}</Typography.Title>
                <Typography.Paragraph
                    style={{
                        color: Color.White,
                        fontSize: 'medium',
                        display: 'inline-block',
                        backgroundColor: isActive
                            ? Color.PrimaryRed
                            : Color.Gray,
                        padding: '0.5em 1.5em',
                        borderRadius: '0.5em',
                        margin: 0,
                    }}>
                    {t(`translation:alert.activeName.${isActive}`)}
                </Typography.Paragraph>
            </div>
            <Descriptions column={2}>
                <Descriptions.Item label={t('translation:alert.validFrom')}>
                    {new Date(alert.validFrom).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label={t('translation:alert.validUntil')}>
                    {new Date(alert.validUntil).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item
                    span={2}
                    label={t('translation:alert.receiverGroups')}>
                    {alert.receiverGroups?.length ?? 0 > 0
                        ? alert.receiverGroups?.map(group => (
                              <span key={group.id}>
                                  <ReceiverGroupTag receiverGroup={group} />
                              </span>
                          ))
                        : t('translation:edit.all')}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions column={1}>
                <Descriptions.Item label={t('translation:alert.creatorId')}>
                    {alert.creator?.firstName} {alert.creator?.lastName}{' '}
                    <small>
                        {', '}
                        {alert.creator?.department}
                    </small>
                    {', '}
                    {new Date(alert.createdAt).toLocaleString()}
                </Descriptions.Item>
                {showUpdater && (
                    <Descriptions.Item label={t('translation:alert.updater')}>
                        {alert.updater?.firstName} {alert.updater?.lastName}{' '}
                        <small>
                            {', '}
                            {alert.updater?.department}
                        </small>
                        {', '}
                        {new Date(alert.createdAt).toLocaleString()}
                    </Descriptions.Item>
                )}
            </Descriptions>

            <Typography.Title level={4}>
                {t('translation:alert.description')}
            </Typography.Title>
            <LineBreakSensitiveParagraph>
                {alert.description}
            </LineBreakSensitiveParagraph>
            {alert.instructions && (
                <>
                    <Typography.Title level={4}>
                        {t('translation:alert.instructions')}
                    </Typography.Title>
                    <LineBreakSensitiveParagraph>
                        {alert.instructions}
                    </LineBreakSensitiveParagraph>
                </>
            )}
            <ContactsView contacts={alert.contacts} />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    paddingTop: 16,
                }}>
                <Space>
                    <Button onClick={() => props.onEdit?.(alert)}>
                        {t('translation:edit.edit')}
                    </Button>
                    <Popconfirm
                        onConfirm={deactivateAlert}
                        title={t('translation:alert.action.deactivateConfirm')}>
                        <Button>
                            {t('translation:alert.action.deactivate')}
                        </Button>
                    </Popconfirm>
                </Space>
                <div style={{flex: 1}} />
                <Space>
                    <Button type={'primary'} onClick={() => props.onClose?.()}>
                        {t('translation:edit.ok')}
                    </Button>
                </Space>
            </div>
        </div>
    );
};

const ContactsView = (props: {contacts: Alert['contacts']}) => {
    const {t} = useTranslation();

    if (!props.contacts || props.contacts.length === 0) {
        return null;
    }

    const items = props.contacts.map(contact => {
        return {
            key: contact.id,
            label: `${contact.employee.firstName} ${contact.employee.lastName}`,
            children: (
                <>
                    <Descriptions key={contact.id} column={2} bordered={false}>
                        <Descriptions.Item
                            label={t('translation:emergencyContact.role')}>
                            <b>{contact.role}</b>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={t('translation:employee.department')}>
                            <b>{contact.role}</b>
                        </Descriptions.Item>
                        {contact.contactData?.map((data, i) => (
                            <Descriptions.Item
                                span={2}
                                key={i}
                                label={
                                    t(
                                        `translation:emergencyContact.contactData.${data.kind}`,
                                    ) + (data.label ? ` (${data.label})` : '')
                                }>
                                {data.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </>
            ),
        };
    });

    return (
        <div style={{marginBottom: 32}}>
            <Typography.Title level={4}>
                {t('translation:alert.contacts')}
            </Typography.Title>
            <Collapse items={items} />
        </div>
    );
};

const LineBreakSensitiveParagraph = (props: {children: string}) => {
    const lines = props.children.split('\n').filter(l => l.trim());

    return (
        <>
            {lines.map((line, i) => (
                <Typography.Paragraph key={i}>{line}</Typography.Paragraph>
            ))}
        </>
    );
};
