import {LockOutlined, LogoutOutlined, UserOutlined} from '@ant-design/icons';
import {Menu, Modal} from 'antd';
import {useAuth} from '../../../auth/AuthProvider';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {ChangePasswordModal} from './ChangePasswordModal';

export const UserMenu = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [showPasswordChangeModal, setShowPasswordChangeModal] =
        useState(false);

    const logout = () => {
        auth.logout();
        navigate('/');
    };

    return (
        <>
            <Modal
                open={showPasswordChangeModal}
                footer={null}
                onCancel={() => setShowPasswordChangeModal(false)}>
                <ChangePasswordModal
                    onClose={() => setShowPasswordChangeModal(false)}
                />
            </Modal>
            <Menu
                selectedKeys={[]}
                mode={'vertical'}
                items={[
                    {
                        key: 'logout',
                        label:
                            auth.user?.employee?.firstName +
                            ' ' +
                            auth.user?.employee?.lastName,
                        icon: <UserOutlined />,
                        children: [
                            {
                                key: 'password',
                                label: 'Passwort ändern',
                                icon: <LockOutlined />,
                                onClick: () => setShowPasswordChangeModal(true),
                            },
                            {
                                key: 'logout',
                                label: 'Anmelden',
                                icon: <LogoutOutlined />,
                                onClick: logout,
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};
