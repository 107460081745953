import {
    App,
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    Popconfirm,
    Select,
    Space,
    Typography,
    UploadFile,
} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Dragger from 'antd/es/upload/Dragger';
import {
    AlertOutlined,
    InfoCircleOutlined,
    PictureFilled,
    WarningOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {Alert, AlertDocument, AlertLevel, CreateAlert} from '../../model/Alert';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Api} from '../../api/endpoints';
import {RcFile} from 'antd/lib/upload';
import {resizeImage} from '../../common/util/imageResize';
import dayjs, {Dayjs} from 'dayjs';
import {AlertIcon, AlertIconMap} from './components/AlertIcon';
import {ObjectSelect} from '../../components/input/ObjectSelect';
import {ReceiverSelection} from '../../components/input/ReceiverSelection';
import {ImageDragger, ImageDraggerValue} from './components/ImageDragger';
import {AlertEditCard} from './components/AlertEditCard';
import {InputItemAlertLevel} from './components/InputItemAlertLevel';
import {
    DurationPicker,
    parseDurationSpecAsMinutes,
} from '../../components/input/DurationPicker';
import {InputItemEmergencyContactSelection} from './components/InputItemEmergencyContactSelection';
import {useLargeModal} from './components/useLargeModal';
import {useInvalidate} from '../../components/invalidate/Invalidate';
import {AlertTemplate} from '../../model/AlertTemplate';

interface CreateAlertForm {
    document: AlertDocument;
    contactIds: string[];
    receiverGroupIds: string[];
    valid: [Dayjs, Dayjs];
    image: ImageDraggerValue;
}

export const useAlertEditModal = () => {
    return useLargeModal(
        (
            close,
            args: {
                alert?: Alert;
                template?: AlertTemplate;
            },
        ) => <AlertCreatePage {...args} onFinish={close} />,
    );
};

const AlertCreatePage = (props: {
    onFinish: () => void;
    alert?: Alert;
    template?: AlertTemplate;
}) => {
    const [form] = Form.useForm();
    const {notification} = App.useApp();
    const handleError = useErrorHandling();

    const {t} = useTranslation();
    const [employeeCount, setEmployeeCount] = React.useState(0);

    const [loading, setLoading] = React.useState(false);

    const {invalidate} = useInvalidate();

    const onFinish = (record: CreateAlertForm) => {
        const validFrom = record.valid[0].toISOString();
        const validUntil = record.valid[1].toISOString();
        setLoading(true);

        const createArgs: CreateAlert = {
            document: record.document,
            target: {
                receiverGroupIds: record.receiverGroupIds,
            },
            contactIds: record.contactIds,
            validUntil,
            validFrom,
            image: record.image.base64,
            imageBlobId: record.image.blobId,
        };

        const promise =
            props.alert && props.alert.id
                ? Api.alert.update(props.alert, createArgs)
                : Api.alert.create(createArgs);

        promise
            .then(res => {
                props.onFinish();
                invalidate();

                notification.success({
                    message: t('alert.success'),
                    description: t('alert.successDescription', {
                        count: employeeCount,
                    }),
                });
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    };

    let initialValues: CreateAlertForm = {
        document: {
            level: AlertLevel.INFo,
            title: '',
            description: '',
            instructions: '',
            icon: Object.keys(AlertIconMap)[0],
        },
        contactIds: [],
        receiverGroupIds: [],
        valid: [dayjs(), dayjs().add(1, 'day')],
        image: {
            blobId: undefined,
        },
    };

    if (props.alert) {
        initialValues = {
            document: {
                level: props.alert.level,
                title: props.alert.title,
                description: props.alert.description,
                instructions: props.alert.instructions,
                icon: props.alert.icon,
            },
            contactIds: props.alert.contacts.map(c => c.id),
            receiverGroupIds: props.alert.receiverGroups?.map(g => g.id) ?? [],
            valid: [
                dayjs(props.alert.validFrom),
                dayjs(props.alert.validUntil),
            ],
            image: {
                blobId: props.alert.imageBlobId,
            },
        };
    }

    if (props.template) {
        const min = parseDurationSpecAsMinutes(props.template.validDuration);
        initialValues = {
            document: {
                level: props.template.level,
                title: props.template.title,
                description: props.template.description,
                instructions: props.template.instructions,
                icon: props.template.icon,
            },
            contactIds: props.template.contacts.map(c => c.id),
            receiverGroupIds:
                props.template.receiverGroups?.map(g => g.id) ?? [],
            valid: [dayjs(), dayjs().add(min, 'minute')],
            image: {
                blobId: props.template.imageBlobId,
            },
        };
    }

    return (
        <Form
            preserve={false}
            form={form}
            initialValues={initialValues}
            layout={'vertical'}
            onFinish={onFinish}>
            <div style={{height: 32}} />
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'stretch',
                    gap: 32,
                }}>
                <AlertEditCard />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '40%',
                    }}>
                    <Typography.Title level={2}>
                        {props.alert
                            ? t('alert.editAlert')
                            : t('alert.createAlert')}
                    </Typography.Title>

                    <InputItemAlertLevel />

                    <Form.Item
                        label={t('translation:alert.receiverGroup')}
                        name={'receiverGroupIds'}>
                        <ReceiverSelection
                            setCount={setEmployeeCount}
                            setLoading={setLoading}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('translation:alert.valid')}
                        name={'valid'}>
                        <DatePicker.RangePicker
                            style={{width: '100%'}}
                            showTime
                        />
                    </Form.Item>
                    <Typography.Paragraph type={'secondary'}>
                        Die Gültigkeit der Alarmierung bestimmt, wie lange der
                        Alarm in der App sichtbar ist. Anschließend wird der
                        Alarm automatisch archiviert.
                    </Typography.Paragraph>
                    <InputItemEmergencyContactSelection />
                    <div style={{flex: 1}} />
                    <Space
                        style={{
                            marginTop: 16,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <Button onClick={props.onFinish}>
                            {t('translation:edit.abort')}
                        </Button>
                        {!props.alert && (
                            <Popconfirm
                                title={t('alert.confirmSend')}
                                onConfirm={() => {
                                    form.submit();
                                }}>
                                <Button type={'primary'} loading={loading}>
                                    {t('alert.sendButton', {
                                        count: employeeCount,
                                    })}
                                </Button>
                            </Popconfirm>
                        )}
                        {props.alert && (
                            <Popconfirm
                                title={t('edit.confirmSave')}
                                onConfirm={() => {
                                    form.submit();
                                }}>
                                <Button type={'primary'} loading={loading}>
                                    {t('edit.save')}
                                </Button>
                            </Popconfirm>
                        )}
                    </Space>
                </div>
            </div>
        </Form>
    );
};
