import {Input, InputNumber, Space} from 'antd';
import {useEffect, useState} from 'react';

import './DurationPicker.css';

export const DurationPicker = (props: {
    value?: string;
    onChange?: (value: string) => void;
}) => {
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [days, setDays] = useState<number>(0);

    const cleanValue = (value: {
        minutes: number;
        hours: number;
        days: number;
    }) => {
        let duration = value.days * 60 * 24 + value.hours * 60 + value.minutes;

        const maxDuration = 24 * 60 * 90;

        if (duration > maxDuration) {
            duration = maxDuration;
        }

        const minutes = duration % 60;
        const hours = ((duration - minutes) / 60) % 24;
        const days = (duration - minutes - hours * 60) / (60 * 24);

        return {minutes, hours, days};
    };

    const onBlur = () => {
        const values = cleanValue({days, hours, minutes});
        setMinutes(values.minutes);
        setHours(values.hours);
        setDays(values.days);

        if (props.onChange) {
            props.onChange(
                `${values.days * 24 + values.hours}h${values.minutes}m`,
            );
        }
    };

    useEffect(() => {
        if (props.value) {
            const values = cleanValue({
                days: 0,
                hours: 0,
                minutes: parseDurationSpecAsMinutes(props.value),
            });

            setMinutes(values.minutes);
            setHours(values.hours);
            setDays(values.days);
        }
    }, [props.value]);

    return (
        <Space.Compact style={{display: 'flex'}}>
            <InputNumber
                className={'duration-picker-left'}
                addonAfter={'Tage'}
                min={0}
                value={days}
                onChange={e => setDays(e ?? 0)}
                onBlur={onBlur}
            />
            <InputNumber
                className={'duration-picker-middle'}
                addonAfter={'Stunden'}
                min={0}
                value={hours}
                onChange={e => setHours(e ?? 0)}
                onBlur={onBlur}
            />
            <InputNumber
                className={'duration-picker-right'}
                addonAfter={'Minuten'}
                min={0}
                value={minutes}
                onChange={e => setMinutes(e ?? 0)}
                onBlur={onBlur}
            />
        </Space.Compact>
    );
};

export const parseDurationSpecAsMinutes = (value: string) => {
    let totalMinutes = 0;
    let acc = 0;
    for (let i = 0; i < value.length; i++) {
        if (value[i] === 'd') {
            totalMinutes += acc * 24 * 60;
            acc = 0;
        } else if (value[i] === 'h') {
            totalMinutes += acc * 60;
            acc = 0;
        } else if (value[i] === 'm') {
            totalMinutes += acc;
            acc = 0;
        }

        const digit = parseInt(value[i]);
        if (isNaN(digit)) {
            continue;
        }
        acc = acc * 10 + digit;
    }

    return totalMinutes;
};
