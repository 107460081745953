import {Card, Modal, Space, Steps, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {CSVEffectedRow, CSVSyncRow} from '../../model/core';
import {Api} from '../../api/endpoints';
import {
    formatAxiosError,
    useErrorHandling,
} from '../../components/error/ErrorHandlerProvider';
import {CsvSyncContentContainer} from './component/ContentContainer';
import {CsvStepUpload} from './component/CsvStepUpload';
import {CsvStepMapCsv} from './component/CsvStepMapCsv';
import {CsvStepShowResult} from './component/CsvStepShowResult';
import {CsvStepDone} from './component/CsvStepDone';
import {Employee} from '../../model/Employee';
import Papa from 'papaparse';
import {AxiosError} from 'axios';
import {useInvalidate} from '../../components/invalidate/Invalidate';

interface CSVModalProps {
    closeFn: () => void;
    open: boolean;
}

export const CSVModal = (props: CSVModalProps) => {
    const {t} = useTranslation();
    const handleError = useErrorHandling();

    const [step, setStep] = React.useState(0);
    const [csvContent, setCSVContent] = React.useState<
        {[key: string]: string}[]
    >([]);
    const [processedCsv, setProcessedCsv] = React.useState<CSVSyncRow[]>([]);
    const [done, setDone] = React.useState(false);
    const [error, setError] = React.useState<AxiosError>();

    const {invalidate} = useInvalidate();

    const abortFn = () => {
        props.closeFn();
        setStep(0);
        invalidate();
    };

    const performUpdate = async (effect: CSVEffectedRow) => {
        try {
            setStep(step + 1);
            await Api.csvSyncPeform(processedCsv, effect);
            setDone(true);
        } catch (e: any) {
            handleError(e);
            setDone(true);
            setError(e);
        }
        invalidate();
    };

    const steps = [
        {
            title: t('csvSync.step.upload.title'),
            content: (
                <CsvStepUpload
                    onAbort={abortFn}
                    onFinish={data => {
                        setCSVContent(data);
                        setStep(step + 1);
                    }}
                />
            ),
        },
        {
            title: t('csvSync.step.mapping.title'),
            content: (
                <CsvStepMapCsv
                    onAbort={abortFn}
                    onBack={() => setStep(step - 1)}
                    csvContent={csvContent}
                    onFinish={content => {
                        setProcessedCsv(content);
                        setStep(step + 1);
                    }}
                />
            ),
        },
        {
            title: t('csvSync.step.check.title'),
            content: (
                <CsvStepShowResult
                    processedCsv={processedCsv}
                    onAbort={abortFn}
                    onBack={() => setStep(step - 1)}
                    onFinish={effect => {
                        performUpdate(effect).then();
                    }}
                />
            ),
        },
        {
            title: t('csvSync.step.done.title'),
            content: (
                <CsvStepDone done={done} error={error} onFinish={abortFn} />
            ),
        },
    ];

    return (
        <Modal
            open={props.open}
            footer={null}
            destroyOnClose={true}
            width={'80rem'}
            onCancel={abortFn}>
            <Typography.Title level={3}>
                {t('csvSync.card.title')}
            </Typography.Title>
            <Space
                size={'large'}
                direction={'vertical'}
                style={{width: '100%'}}>
                <Card style={{maxWidth: '40rem'}}>
                    <Typography.Paragraph>
                        {t('csvSync.description')}
                    </Typography.Paragraph>
                </Card>

                <Steps current={step} items={steps} />
                {steps[step].content}
            </Space>
        </Modal>
    );
};
