import {
    Alert,
    Button,
    Card,
    Flex,
    Form,
    Input,
    Space,
    theme,
    Typography,
} from 'antd';
import {useAuth} from '../../auth/AuthProvider';
import {useNavigate} from 'react-router-dom';
import {UserOutlined} from '@ant-design/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CenterCardLayout} from './component/CenterCardLayout';
import {PasswordInput} from './component/PasswordInput';

export const Login = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [mail, setMail] = React.useState('');
    const [password, setPassword] = React.useState('');

    // Redirect if already logged in
    React.useEffect(() => {
        if (auth.loggedIn) {
            navigate('/alert/');
        }
    });

    const onLogin = async () => {
        if (!mail || !password) {
            setErrorMessage(t('translation:login.errorEmpty'));
            return;
        }

        const res = await auth.login(mail.trim(), password.trim());
        if (res.ok) {
            setErrorMessage('');
            navigate('/alert/');
        } else {
            switch (res.message) {
                case 'CREDENTIALS':
                    setErrorMessage(t('translation:login.wrongPassword'));
                    break;
                default:
                    setErrorMessage(t('translation:login.error'));
                    break;
            }
        }
    };

    return (
        <CenterCardLayout>
            <Space direction={'vertical'} style={{width: '100%'}}></Space>
            <Form>
                {errorMessage && (
                    <Form.Item>
                        <Alert message={errorMessage} type="error" />{' '}
                    </Form.Item>
                )}
                <Form.Item>
                    <Input
                        value={mail}
                        placeholder={t('translation:login.email')}
                        onChange={e => {
                            setMail(e.target.value);
                            setErrorMessage('');
                        }}
                        size="large"
                        prefix={<UserOutlined />}
                    />
                </Form.Item>

                <Form.Item>
                    <PasswordInput
                        value={password}
                        onChange={v => {
                            setErrorMessage('');
                            setPassword(v);
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={onLogin}
                        type="primary"
                        style={{width: '100%'}}>
                        {t('translation:login.button')}
                    </Button>
                </Form.Item>
            </Form>
            <Typography.Link
                onClick={() => {
                    navigate('/password-request');
                }}>
                {t('login.forgotPassword')}
            </Typography.Link>
        </CenterCardLayout>
    );
};
