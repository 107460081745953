import {Layout, Menu, theme} from 'antd';
import LogoBlack from './logo-black.png';
import LogoWhite from './logo-white.png';
import {Outlet, useNavigate} from 'react-router-dom';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {useAuth} from '../../auth/AuthProvider';
import {PermissionGroup} from '../../model/ManagementUser';
import {useTheme} from '../../App';
import {ErrorBoundary} from '../error/ErrorBoundary';
import {UserMenu} from './internal/UserMenu';

export interface SidebarItem {
    kind: 'Item';
    disabled?: boolean;
    path: string;
    label: string;
    permission: PermissionGroup[];
    icon?: React.ReactNode;
    category?: string;
    element: React.ReactNode;
}

export interface SidebarHeader {
    kind: 'Header';
    label: string;
    children: SidebarTree[];
}

export type SidebarTree = SidebarItem | SidebarHeader;

export interface DashboardLayoutProps {
    sidebar: SidebarTree[];
}

export const DashboardLayout = (props: DashboardLayoutProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const colorTheme = useTheme();

    function filterPermissions(tree: SidebarTree[]): ItemType[] {
        return tree.flatMap((item: SidebarTree) => {
            switch (item.kind) {
                case 'Item':
                    if (
                        item.permission.includes(
                            auth.user?.permissionGroup || PermissionGroup.NONE,
                        )
                    ) {
                        return [
                            {
                                key: item.path,
                                label: item.label,
                                icon: item.icon,
                                disabled: item.disabled ?? false,
                            },
                        ];
                    } else {
                        return [];
                    }
                case 'Header': {
                    const children = filterPermissions(item.children);
                    if (children.length > 0) {
                        return [
                            {
                                type: 'group',
                                key: '',
                                label: item.label,
                                icon: null,
                                children: children,
                                disabled: false,
                            },
                        ];
                    } else {
                        return [];
                    }
                }
            }
        });
    }

    const items = filterPermissions(props.sidebar);
    const onSelect = (event: {key: string}) => {
        navigate(event.key);
    };

    return (
        <Layout style={{minHeight: '100%'}}>
            <Layout.Header
                style={{
                    padding: 0,
                    background: colorBgContainer,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                {colorTheme.theme == 'light' && (
                    <img
                        src={LogoBlack}
                        alt={'logo'}
                        style={{height: '82%', padding: 8}}
                        onDoubleClick={() => colorTheme.setTheme('dark')}
                    />
                )}
                {colorTheme.theme == 'dark' && (
                    <img
                        src={LogoWhite}
                        alt={'logo'}
                        style={{height: '82%', padding: 8}}
                        onDoubleClick={() => colorTheme.setTheme('light')}
                    />
                )}
            </Layout.Header>
            <Layout>
                <Layout.Sider
                    style={{
                        background: colorBgContainer,
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}>
                        <Menu
                            mode={'inline'}
                            items={items}
                            selectedKeys={[location.pathname]}
                            onSelect={onSelect}
                        />
                        <div
                            style={{
                                position: 'sticky',
                                bottom: 8,
                            }}>
                            <UserMenu />
                        </div>
                    </div>
                </Layout.Sider>

                <Layout.Content
                    style={{
                        margin: 32,
                        padding: 32,
                        backgroundColor: colorBgContainer,
                    }}>
                    <ErrorBoundary>
                        <Outlet />
                    </ErrorBoundary>
                </Layout.Content>
                <Layout.Footer style={{textAlign: 'center'}}></Layout.Footer>
            </Layout>
        </Layout>
    );
};
