import {CenterCardLayout} from './component/CenterCardLayout';
import {Button, Form, Input, Result, Typography} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {UserOutlined} from '@ant-design/icons';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {Api} from '../../api/endpoints';

export const ResetPassword = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const errorHandler = useErrorHandling();

    const [sent, setSent] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const reset = () => {
        Api.sendPasswordResetMail(email)
            .catch(errorHandler)
            .then(() => {
                setSent(true);
            });
    };

    return (
        <CenterCardLayout onClose={() => navigate('/')}>
            <Typography.Title level={4} style={{marginTop: 8}}>
                {t('resetPassword.heading')}
            </Typography.Title>
            {!sent && (
                <>
                    <Typography.Paragraph>
                        {t('resetPassword.body')}
                    </Typography.Paragraph>
                    <Form>
                        <Form.Item>
                            <Input
                                placeholder={t('login.email')}
                                size="large"
                                prefix={<UserOutlined />}
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                disabled={!email}
                                onClick={reset}
                                type="primary"
                                style={{width: '100%'}}>
                                {t('translation:resetPassword.button')}
                            </Button>
                        </Form.Item>
                    </Form>
                    <Typography.Link
                        onClick={() => {
                            navigate('/password-request/sms');
                        }}>
                        {t('resetPassword.sms')}
                    </Typography.Link>
                </>
            )}
            {sent && (
                <>
                    <Result
                        status="success"
                        title={t('resetPassword.success.heading')}
                        subTitle={t('resetPassword.success.body')}
                        extra={[
                            <Button
                                type="primary"
                                key="console"
                                onClick={() => navigate('/')}>
                                {t('resetPassword.success.button')}
                            </Button>,
                        ]}
                    />
                </>
            )}
        </CenterCardLayout>
    );
};
