import {Employee} from './Employee';
import {Company} from './Company';

export interface ManagementUser {
    id: string;
    employeeId: string;
    permissionGroup: PermissionGroup;
    status: ManagementUserStatus;
    employee?: Employee;
    createdAt: string;
    updatedAt: string;
    company?: Company;
}

export enum PermissionGroup {
    ALL = 'ALL',
    NONE = 'NONE',
}

export enum ManagementUserStatus {
    ACTIVE = 'ACTIVE',
    INVITED = 'INVITED',
    BLOCKED = 'BLOCKED',
}

export interface CreateManagementUser {
    permissionGroup: PermissionGroup;
    status: ManagementUserStatus;
}
