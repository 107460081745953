import {InvalidateReceiver} from '../../components/invalidate/Invalidate';
import {Card, Modal, Space, Typography} from 'antd';
import React from 'react';
import {AlertCard} from './components/AlertCard';
import {AlertsTable} from './components/AlertsTable';
import {useAlertEditModal} from './useAlertEditModal';
import {useTranslation} from 'react-i18next';
import {useAlertShowModal} from './useAlertShowModal';

export const AlertOverviewPage = () => {
    const {t} = useTranslation();

    const createModal = useAlertEditModal();
    const showModal = useAlertShowModal();

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Title>{t('translation:alert.item')}</Typography.Title>

            <AlertCard
                style={{maxWidth: '40rem'}}
                onClick={() => createModal({})}
            />

            <Card>
                <AlertsTable
                    editAlert={item => {
                        createModal({alert: item});
                    }}
                    showAlert={item => {
                        showModal({alert: item});
                    }}
                />
            </Card>
        </Space>
    );
};
