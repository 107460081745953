import React from 'react';
import './App.css';
import {App as AntApp, ConfigProvider} from 'antd';
import {AuthProvider} from './auth/AuthProvider';
import {ErrorHandlerProvider} from './components/error/ErrorHandlerProvider';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ProtectedRoutes} from './auth/ProtectedRoutes';
import {
    DashboardLayout,
    SidebarTree,
} from './components/layout/DashboardLayout';
import {Login} from './features/auth/Login';
import {PageNotFound} from './components/error/NotFound';
import {AntLanguage} from './i18n/translation';
import {ThemeDark, ThemeLight} from './common/Color';
import {PermissionGroup} from './model/ManagementUser';
import {
    AlertOutlined,
    AreaChartOutlined,
    CopyOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {DummyPage} from './features/dummyPage/dummyPage';
import {EmployeePage} from './features/employee/EmployeePage';
import {ReceiverGroupPage} from './features/receiverGroup/ReceiverGroupPage';
import {AlertOverviewPage} from './features/alert/AlertOverviewPage';
import {EmergencyContactsPage} from './features/emergencyContacts/EmergencyContactsPage';
import {EmergencyContactGroupPage} from './features/emergencyContacts/EmergencyContactGroupPage';
import {ManagerPage} from './features/manager/ManagerPage';
import {ResetPassword} from './features/auth/ResetPassword';
import {Register} from './features/auth/Register';
import {ResetPasswordSMS} from './features/auth/ResetPasswordSMS';
import {CompanySettings} from './features/settings/CompanySettings';
import {TemplatePage} from './features/alert/TemplatePage';
import {InvalidateReceiver} from './components/invalidate/Invalidate';

const sidebar: SidebarTree[] = [
    {
        kind: 'Header',
        label: 'Dashboard',
        children: [
            {
                kind: 'Item',
                path: '/dashboard',
                label: 'Übersicht',
                permission: [PermissionGroup.ALL],
                disabled: true,
                icon: <AreaChartOutlined />,
                element: <DummyPage name={'Dashboard'} />,
            },
        ],
    },
    {
        kind: 'Header',
        label: 'Meldungen',
        children: [
            {
                kind: 'Item',
                path: '/alert/',
                label: 'Meldungen',
                permission: [PermissionGroup.ALL],
                icon: <AlertOutlined />,
                element: <AlertOverviewPage />,
            },
            {
                kind: 'Item',
                path: '/alert/template',
                label: 'Vorlagen',
                permission: [PermissionGroup.ALL],
                icon: <CopyOutlined />,
                element: <TemplatePage />,
            },
        ],
    },
    {
        kind: 'Header',
        label: 'Ansprechpartner',
        children: [
            {
                kind: 'Item',
                label: 'Ansprechpartner',
                path: '/emergencyContacts/contacts',
                permission: [PermissionGroup.ALL],
                icon: <UserOutlined />,
                element: <EmergencyContactsPage />,
            },
            {
                kind: 'Item',
                label: 'Kontaktgruppen',
                path: '/emergencyContacts/contactGroups',
                permission: [PermissionGroup.ALL],
                icon: <TeamOutlined />,
                element: <EmergencyContactGroupPage />,
            },
        ],
    },
    {
        kind: 'Header',
        label: 'Unternehmen',
        children: [
            {
                kind: 'Item',
                path: '/company/infos',
                label: 'Daten',
                permission: [PermissionGroup.ALL],
                icon: <ShopOutlined />,
                element: <CompanySettings />,
            },
            {
                kind: 'Item',
                path: '/company/employees',
                label: 'Mitarbeiter',
                permission: [PermissionGroup.ALL],
                icon: <UserOutlined />,
                element: <EmployeePage />,
            },
            {
                kind: 'Item',
                path: '/company/groups',
                label: 'Gruppen',
                permission: [PermissionGroup.ALL],
                icon: <TeamOutlined />,
                element: <ReceiverGroupPage />,
            },
            {
                kind: 'Item',
                path: '/company/admins',
                label: 'Administration',
                permission: [PermissionGroup.ALL],
                icon: <AreaChartOutlined />,
                element: <ManagerPage />,
            },
        ],
    },
];

function sidebarTreeToRoutes(
    tree: SidebarTree,
): {path: string; element: React.ReactNode}[] {
    switch (tree.kind) {
        case 'Item':
            return [{path: tree.path, element: tree.element}];
        case 'Header':
            return tree.children.flatMap(sidebarTreeToRoutes);
    }
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login />,
    },
    {
        path: '/password-request',
        element: <ResetPassword />,
    },
    {
        path: '/password-request/sms',
        element: <ResetPasswordSMS />,
    },
    {
        path: '/password-reset',
        element: <Register kind={'reset'} />,
    },
    {
        path: '/register',
        element: <Register kind={'register'} />,
    },
    {
        path: '/',
        element: <ProtectedRoutes />,
        children: [
            {
                element: <DashboardLayout sidebar={sidebar} />,
                path: '/',
                children: sidebar.flatMap(sidebarTreeToRoutes).concat([
                    {
                        path: '*',
                        element: <PageNotFound />,
                    },
                ]),
            },
        ],
    },
]);

const ThemeContext = React.createContext({
    theme: 'light' as 'light' | 'dark',
    setTheme: (theme: 'light' | 'dark') => {
        // NOP
    },
});

export const useTheme = () => React.useContext(ThemeContext);

function App() {
    let initialTheme = localStorage.getItem('theme');
    if (!initialTheme) {
        initialTheme = 'light';
    }
    const [theme, setTheme] = React.useState<'light' | 'dark'>(
        initialTheme as 'light' | 'dark',
    );
    const options = {
        theme,
        setTheme,
    };

    return (
        <ConfigProvider
            locale={AntLanguage}
            theme={theme == 'light' ? ThemeLight : ThemeDark}>
            <InvalidateReceiver
                onInvalidate={() => {
                    console.log('invalidating content');
                }}>
                <AntApp className="root">
                    <AuthProvider>
                        <ThemeContext.Provider value={options}>
                            <ErrorHandlerProvider>
                                <RouterProvider
                                    router={router}></RouterProvider>
                            </ErrorHandlerProvider>
                        </ThemeContext.Provider>
                    </AuthProvider>
                </AntApp>
            </InvalidateReceiver>
        </ConfigProvider>
    );
}

export default App;
