import {Card, Modal, Typography} from 'antd';
import React from 'react';
import {
    InvalidateReceiver,
    useInvalidate,
} from '../../components/invalidate/Invalidate';
import {EmergencyContactTable} from './component/EmergencyContactTable';
import {EmergencyContactCreatePage} from './EmergencyContactCreatePage';
import {EmergencyContact} from '../../model/EmergencyContact';
import {useTranslation} from 'react-i18next';

export const EmergencyContactsPage = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = React.useState(false);
    const [modalEditItem, setModalEditItem] =
        React.useState<EmergencyContact>();

    return (
        <>
            <Modal
                destroyOnClose
                open={showModal}
                footer={null}
                width={'50rem'}
                onCancel={() => setShowModal(false)}>
                <EmergencyContactCreatePage
                    editItem={modalEditItem}
                    onFinish={() => {
                        setShowModal(false);
                    }}
                />
            </Modal>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                }}>
                <Typography.Title>
                    {t('translation:emergencyContact.item')}
                </Typography.Title>
                <Card>
                    <EmergencyContactTable
                        onAdd={() => {
                            setShowModal(true);
                            setModalEditItem(undefined);
                        }}
                        onEdit={record => {
                            setShowModal(true);
                            setModalEditItem(record);
                        }}
                    />
                </Card>
            </div>
        </>
    );
};
