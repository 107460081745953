import axios from 'axios';
import {LoginResult} from '../model/LoginResult';
import {CreateManagementUser, ManagementUser} from '../model/ManagementUser';
import {Employee} from '../model/Employee';
import {SearchResults} from '../model/SearchResults';
import {ReceiverGroup} from '../model/ReceiverGroup';
import {CSVEffectedRow, CSVSyncRow} from '../model/core';
import {Alert, CreateAlert} from '../model/Alert';
import {
    CreateEmergencyContact,
    EmergencyContact,
} from '../model/EmergencyContact';
import {
    CreateEmergencyContactGroup,
    EmergencyContactGroup,
} from '../model/EmergencyContactGroup';
import {AlertTemplate, UpdateAlertTemplate} from '../model/AlertTemplate';

export type SearchParams =
    | {
          [key: string]: string;
      }
    | [string, string][];

export const Api = {
    TODO: (s: string) => {
        throw new Error(`Not implemented ${s}`);
    },
    login: async (email: string, password: string): Promise<LoginResult> => {
        const res = await axios.post('/auth/login', {email, password});
        return res.data;
    },
    register: async (code: string, password: string): Promise<LoginResult> => {
        const res = await axios.post('/auth/register', {code, password});
        return res.data;
    },
    sendPasswordResetMail: async (email: string): Promise<boolean> => {
        const res = await axios.post('/auth/password/email/request', {email});
        return res.data == 'ok';
    },
    passwordSMS: {
        //
        send: async (
            phone: string,
        ): Promise<{
            claim: string;
            codeLength: number;
        }> => {
            const res = await axios.post('/auth/password/sms/send', {
                phone,
            });
            return res.data;
        },
        //
        verify: async (
            claim: string,
            code: string,
        ): Promise<{
            claim: string;
        }> => {
            const res = await axios.post('/auth/password/sms/verify', {
                claim,
                code,
            });
            return res.data;
        },
        //
        reset: async (
            claim: string,
            password: string,
        ): Promise<LoginResult> => {
            const res = await axios.post('/auth/password/sms/reset', {
                claim,
                password,
            });
            return res.data;
        },
    },
    currentUser: async (): Promise<ManagementUser> => {
        const res = await axios.get('/auth/current');
        return res.data;
    },
    csvSyncDryRun: async (rows: CSVSyncRow[]): Promise<CSVEffectedRow> => {
        const res = await axios.post('/sync/csv/dry-run', {rows});
        return res.data;
    },
    csvSyncPeform: async (
        rows: CSVSyncRow[],
        effect: CSVEffectedRow,
    ): Promise<CSVEffectedRow> => {
        const res = await axios.post('/sync/csv/perform', {
            rows,
            expectedEffect: effect,
        });
        return res.data;
    },
    imageBlobUrl: (imageId: string) => {
        let url = axios.defaults.baseURL || '';
        if (!url.endsWith('/')) {
            url += '/';
        }
        return `${url}blob/${imageId}`;
    },
    imageBlobUrlOptional: (imageId?: string): string | undefined => {
        return imageId ? Api.imageBlobUrl(imageId) : undefined;
    },
    changePassword: (newPassword: string, oldPassword: string) => {
        return axios.post('/auth/changePassword', {
            newPassword,
            oldPassword,
        });
    },
    company: {
        update: async (data: {name: string; logo: string}) => {
            return axios.put('/company/', data);
        },
    },
    alert: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<Alert>> => {
                    const res = await axios.get('/alert/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        create: async (
            create: CreateAlert,
        ): Promise<{
            sent: number;
        }> => {
            const res = await axios.post('/alert/', create);
            return res.data as {
                sent: number;
            };
        },
        update: async (old: Alert, update: CreateAlert): Promise<void> => {
            await axios.put(`/alert/${old.id}`, update);
        },
    },
    alertTemplate: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<AlertTemplate>> => {
                    const res = await axios.get('/alertTemplate/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        create: async (create: UpdateAlertTemplate): Promise<AlertTemplate> => {
            return (await axios.post('/alertTemplate/', create)).data;
        },
        get: async (id: string): Promise<AlertTemplate> => {
            const res = await axios.get(`/alertTemplate/${id}`);
            return res.data;
        },
        delete: async (id: string): Promise<void> => {
            const res = await axios.delete(`/alertTemplate/${id}`);
            return res.data;
        },
        update: async (
            old: AlertTemplate,
            update: UpdateAlertTemplate,
        ): Promise<AlertTemplate> => {
            return (await axios.put(`/alertTemplate/${old.id}`, update)).data;
        },
    },
    employee: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<Employee>> => {
                    const res = await axios.get('/employee/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        put: async (
            employee: Employee,
            receiverGroups: ReceiverGroup[],
        ): Promise<Employee> => {
            const res = await axios.put(`/employee/${employee.id}`, {
                employee,
                receiverGroupIds: receiverGroups.map(g => g.id),
            });
            return res.data;
        },
        post: async (
            employee: Employee,
            receiverGroups: ReceiverGroup[],
        ): Promise<Employee> => {
            const res = await axios.post('/employee/', {
                employee,
                receiverGroupIds: receiverGroups.map(g => g.id),
            });
            return res.data;
        },
        delete: async (id: string): Promise<string> => {
            const _ = await axios.delete(`/employee/${id}`);
            return id;
        },
        reinvite: async (id: string): Promise<string> => {
            const _ = await axios.post(`/employee/${id}/reinvite`);
            return id;
        },
    },
    mangementUser: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<ManagementUser>> => {
                    const res = await axios.get('/managementUser/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        put: async (
            user: ManagementUser,
            update: CreateManagementUser,
        ): Promise<ManagementUser> => {
            const res = await axios.put(`/managementUser/${user.id}`, {
                ...update,
            });
            return res.data;
        },
        post: async (
            update: CreateManagementUser & {employeeId: string},
        ): Promise<ManagementUser> => {
            const res = await axios.post('/managementUser/', {
                ...update,
            });
            return res.data;
        },
        delete: async (user: ManagementUser): Promise<ManagementUser> => {
            const res = await axios.delete(`/managementUser/${user.id}`);
            return res.data;
        },
        reinvite: async (id: string) => {
            const _ = await axios.post(`/managementUser/${id}/reinvite`);
            return id;
        },
    },
    receiverGroup: {
        list: async (): Promise<ReceiverGroup[]> => {
            const res = await axios.get('/receivergroup/');
            return res.data;
        },
        post: async (group: {name: string}): Promise<ReceiverGroup> => {
            const res = await axios.post('/receivergroup/', {
                name: group.name,
            });
            return res.data;
        },
        put: async (group: ReceiverGroup): Promise<ReceiverGroup> => {
            const res = await axios.put(`/receivergroup/${group.id}`, group);
            return res.data;
        },
        delete: async (group: ReceiverGroup): Promise<boolean> => {
            const res = await axios.delete(`/receivergroup/${group.id}`);
            return res.data == 'ok';
        },
    },
    emergencyContact: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<EmergencyContact>> => {
                    const res = await axios.get('/emergencyContact/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        create: async (
            createBody: CreateEmergencyContact,
        ): Promise<boolean> => {
            const res = await axios.post('/emergencyContact/', createBody);
            return res.data == 'ok';
        },
        update: async (
            old: EmergencyContact,
            createBody: CreateEmergencyContact,
        ): Promise<boolean> => {
            const res = await axios.put(
                `/emergencyContact/${old.id}`,
                createBody,
            );
            return res.data == 'ok';
        },
        delete: async (old: EmergencyContact): Promise<boolean> => {
            const res = await axios.delete(`/emergencyContact/${old.id}`);
            return res.data == 'ok';
        },
    },
    emergencyContactGroup: {
        find: (pagsize?: number, searchParams?: SearchParams) =>
            new PagedReader(
                pagsize || 100,
                searchParams || {},
                async (
                    offset?: number,
                    limit?: number,
                    searchParams?: SearchParams,
                ): Promise<SearchResults<EmergencyContactGroup>> => {
                    const res = await axios.get('/emergencyContactGroup/', {
                        params: {
                            ...(offset ? {offset: offset} : {}),
                            ...(limit ? {limit: limit} : {}),
                            ...(searchParams || {}),
                        },
                    });
                    return res.data;
                },
            ),
        create: async (
            createBody: CreateEmergencyContactGroup,
        ): Promise<boolean> => {
            const res = await axios.post('/emergencyContactGroup/', createBody);
            return res.data == 'ok';
        },
        update: async (
            old: EmergencyContactGroup,
            createBody: CreateEmergencyContactGroup,
        ): Promise<boolean> => {
            const res = await axios.put(
                `/emergencyContactGroup/${old.id}`,
                createBody,
            );
            return res.data == 'ok';
        },
        delete: async (old: EmergencyContactGroup): Promise<boolean> => {
            const res = await axios.delete(`/emergencyContactGroup/${old.id}`);
            return res.data == 'ok';
        },
    },
};

export class PagedReader<T> {
    pageSize: number;
    searchParams: SearchParams;
    rawReader: (
        offset?: number,
        limit?: number,
        searchParams?: SearchParams,
    ) => Promise<SearchResults<T>>;
    currentOffset = 0;
    total: number | undefined = undefined;

    constructor(
        pageSize: number,
        searchParams: SearchParams,
        rawReader: (
            offset?: number,
            limit?: number,
            searchParams?: SearchParams,
        ) => Promise<SearchResults<T>>,
    ) {
        this.pageSize = pageSize;
        this.searchParams = searchParams;
        this.rawReader = rawReader;
    }

    async raw(
        offset?: number,
        limit?: number,
        searchParams?: SearchParams,
    ): Promise<SearchResults<T>> {
        return this.rawReader(offset, limit, searchParams || {});
    }

    async getPage(page: number): Promise<T[]> {
        this.setPageNumber(page);
        return this.next();
    }

    setPageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.currentOffset = 0;
    }

    async all(): Promise<T[]> {
        this.setPageSize(1000000);
        return await this.next();
    }

    async next(): Promise<T[]> {
        if (this.total !== undefined && this.currentOffset >= this.total) {
            return [];
        }
        const res = await this.rawReader(
            this.currentOffset,
            this.pageSize,
            this.searchParams,
        );
        this.total = res.total;
        this.currentOffset += this.pageSize;
        return res.results;
    }

    async getTotal(): Promise<number> {
        if (this.total !== undefined) {
            return this.total;
        }
        const res = await this.rawReader(0, 0, this.searchParams);
        this.total = res.total;
        return this.total;
    }

    async getTotalPages(): Promise<number> {
        const total = await this.getTotal();
        return Math.ceil(total / this.pageSize);
    }

    setPageNumber(page: number) {
        this.currentOffset = (page - 1) * this.pageSize;
    }

    getPageNumber(): number {
        return Math.floor(this.currentOffset / this.pageSize) + 1;
    }
}
