import {
    Button,
    Checkbox,
    Form,
    Input,
    Select,
    SelectProps,
    Space,
    Typography,
} from 'antd';
import React, {ReactNode} from 'react';
import {Api} from '../../api/endpoints';
import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {EmergencyContactGroup} from '../../model/EmergencyContactGroup';
import {useInvalidate} from '../../components/invalidate/Invalidate';
import {
    CreateEmergencyContact,
    EmergencyContact,
} from '../../model/EmergencyContact';
import {EmployeeObjectSelect} from '../employee/component/EmployeeObjectSelect';

interface EmergencyContactCreatePageProps {
    onFinish: () => void;
    editItem?: EmergencyContact;
}

export const EmergencyContactCreatePage = (
    props: EmergencyContactCreatePageProps,
) => {
    const [form] = Form.useForm();

    const {t} = useTranslation();
    const errorHandling = useErrorHandling();
    const {invalidate} = useInvalidate();

    const [_, setDummyRerender] = React.useState(new Date());

    const [emergencyContactGroups, setEmergencyContactGroups] = React.useState<
        EmergencyContactGroup[]
    >([]);

    React.useEffect(() => {
        Api.emergencyContactGroup
            .find()
            .all()
            .then(setEmergencyContactGroups)
            .catch(useErrorHandling);
    }, []);

    const initialValues = (props.editItem && {
        ...props.editItem,
        emergencyContactGroupIds: props.editItem.emergencyContactGroup.map(
            g => g.id,
        ),
    }) || {
        employeeId: null,
        role: '',
        emergencyContactGroupIds: [],
        active: true,
        public: true,
        contactData: [
            {
                kind: 'phone',
                value: '',
            },
        ],
    };

    const contactDataOptions = [
        {
            value: 'phone',
            label: t('translation:emergencyContact.contactData.phone'),
            rules: [
                {
                    required: true,
                    message: t(
                        'translation:emergencyContact.contactData.phoneInvalid',
                    ),
                    pattern: /^[+]?[0-9-]*$/,
                },
            ],
        },
        {
            value: 'email',
            label: t('translation:emergencyContact.contactData.email'),
            rules: [
                {
                    required: true,
                    message: t(
                        'translation:emergencyContact.contactData.emailInvalid',
                    ),
                    pattern: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
                },
            ],
        },
        {
            value: 'place',
            label: t('translation:emergencyContact.contactData.place'),
            rules: [
                {
                    required: true,
                },
            ],
        },
    ];

    const onFinish = (values: CreateEmergencyContact) => {
        (props.editItem
            ? Api.emergencyContact.update(props.editItem, values)
            : Api.emergencyContact.create(values)
        )
            .then(() => {
                invalidate();
                props.onFinish();
            })
            .catch(errorHandling);
    };
    const rules = (name: number) => {
        const selected = form.getFieldValue(['contactData', name, 'kind']);
        const option = contactDataOptions.find(d => d.value == selected);
        return option?.rules || [];
    };

    return (
        <Form
            preserve={false}
            form={form}
            initialValues={initialValues}
            layout={'vertical'}
            onFinish={onFinish}>
            <Space
                direction={'vertical'}
                style={{
                    width: '100%',
                    display: 'flex',
                }}>
                <Typography.Title level={2}>
                    {t('translation:emergencyContact.item') + ' '}
                    {props.editItem
                        ? t('translation:edit.edit').toLowerCase()
                        : t('translation:edit.add').toLowerCase()}
                </Typography.Title>
                <Space.Compact
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <Form.Item
                        label={'Mitarbeiter'}
                        name={'employeeId'}
                        rules={[{required: true}]}
                        style={{flex: 1}}>
                        <EmployeeObjectSelect
                            includeItems={
                                props.editItem ? [props.editItem.employee] : []
                            }
                            filter={e => !e.emergencyContact}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'Rolle'}
                        name={'role'}
                        style={{flex: 1}}
                        rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                </Space.Compact>

                <Form.Item label={'Eigenschaften'} style={{marginBottom: 0}}>
                    <Form.Item
                        name={'active'}
                        style={{marginBottom: 0}}
                        valuePropName="checked">
                        <Checkbox>
                            {t('translation:emergencyContact.active')}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={'public'}
                        style={{marginBottom: 0}}
                        valuePropName="checked">
                        <Checkbox>
                            {t('translation:emergencyContact.public')}
                        </Checkbox>
                    </Form.Item>
                </Form.Item>

                <Typography.Title level={5}>
                    {t('translation:emergencyContact.contactData.item')}
                </Typography.Title>
                <Form.List name={'contactData'}>
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space.Compact
                                    key={key}
                                    style={{display: 'flex'}}>
                                    <Form.Item
                                        style={{flex: 1}}
                                        name={[name, 'kind']}
                                        {...restField}>
                                        <Select
                                            options={contactDataOptions}
                                            onChange={() => {
                                                setDummyRerender(new Date());
                                                window.setTimeout(
                                                    form.validateFields,
                                                    100,
                                                );
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        style={{flex: 3}}
                                        name={[name, 'value']}
                                        rules={rules(name)}
                                        {...restField}>
                                        <Input
                                            placeholder={t(
                                                'translation:emergencyContact.contactData.value',
                                            )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{flex: 2}}
                                        name={[name, 'label']}
                                        {...restField}>
                                        <Input
                                            placeholder={t(
                                                'translation:emergencyContact.contactData.label',
                                            )}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{minWidth: 50}}>
                                        <Button
                                            type="dashed"
                                            onClick={() => remove(name)}
                                            block
                                            icon={
                                                <MinusCircleOutlined />
                                            }></Button>
                                    </Form.Item>
                                </Space.Compact>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                        // set Default manually
                                        // Other Options do not work ?!
                                        setTimeout(() => {
                                            const lastIdx =
                                                form.getFieldValue([
                                                    'contactData',
                                                ]).length - 1;
                                            form.setFieldValue(
                                                [
                                                    'contactData',
                                                    lastIdx,
                                                    'kind',
                                                ],
                                                'phone',
                                            );
                                        }, 10);
                                    }}
                                    block
                                    icon={<PlusOutlined />}></Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item
                    name={'emergencyContactGroupIds'}
                    label={'Kontaktgruppen'}>
                    <Select
                        mode="multiple"
                        options={emergencyContactGroups.map(g => ({
                            value: g.id,
                            label: g.name,
                        }))}
                    />
                </Form.Item>

                <Space
                    style={{
                        marginTop: 16,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    <Button onClick={props.onFinish}>
                        {t('translation:edit.abort')}
                    </Button>
                    <Button type={'primary'} onClick={form.submit}>
                        {props.editItem
                            ? t('translation:edit.edit')
                            : t('translation:edit.add')}
                    </Button>
                </Space>
            </Space>
        </Form>
    );
};
