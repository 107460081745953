import React, {MutableRefObject, ReactNode} from 'react';
import {Searchbar, SearchBarItem} from './Searchbar';
import {Api, PagedReader, SearchParams} from '../../api/endpoints';
import {useTranslation} from 'react-i18next';
import {
    App,
    Button,
    Dropdown,
    Form,
    Modal,
    ModalProps,
    Pagination,
    Popconfirm,
    Space,
    Table,
    Typography,
} from 'antd';
import {emptyId} from '../../model/common';
import {ColumnType, TableRowSelection} from 'antd/es/table/interface';
import {EditableCell, EditableCellProps} from './EditableCell';
import {Hideable} from '../layout/Hideable';
import {useErrorHandling} from '../error/ErrorHandlerProvider';
import {DownOutlined} from '@ant-design/icons';
import {Rule} from 'rc-field-form/lib/interface';
import {SearchableTableActions} from './SearchableTable';
import {useInvalidateListener} from '../invalidate/Invalidate';

interface Model {
    id: string;
}

export interface EditableColumnType<T> extends ColumnType<T> {
    record?: T;
    sorterKey?: string;
    useSkeleton?: boolean;
    editable?:
        | boolean
        | {
              content: (record: T) => React.ReactNode;
              rules?: Rule[];
          };
    required?: boolean;
    regex?: RegExp;
    onCell?: any;
    weight?: number;
}

export interface ExtraAction<T> {
    label: string;
    action: (e: T) => void;
}

export interface ModalArgs<T> {
    existing?: T;
    onFinish: () => void;
    onAbort: () => void;
}

export interface EditableTableActions<T> {
    setSelection: (data: T[]) => void;
    getSelection: () => T[];
    invalidate: () => void;
    triggerCreateNew: () => void;
}

export interface EditableTableProps<T, E extends T> {
    searchbarItems: SearchBarItem[];
    columns: EditableColumnType<T>[];

    newEmpty: () => E;
    find: (params: SearchParams) => PagedReader<T>;
    create?: (item: E) => Promise<void>;
    update?: (item: E) => Promise<void>;
    delete?: (item: T) => Promise<void>;
    pageSize?: number;

    actionButton?: {
        label?: string;
        action?: (record: T) => void;
    };
    extraActions?: ExtraAction<T>[];

    modal?: (props: ModalArgs<T>) => ReactNode;
    modalProps?: ModalProps;

    itemName: (n: number) => string;
    deleteMode?: 'single' | 'multiple';

    actionsRef?: MutableRefObject<EditableTableActions<T> | undefined>;
    onFetch?: (items: T[], count: number) => void;

    defaultSort?: string;
    defaultSortDirection?: 'ASC' | 'DESC';

    customOnCreate?: () => void;
}

export const EditableTable = <T extends Model, E extends T>(
    props: EditableTableProps<T, E>,
) => {
    const {t} = useTranslation();
    const errorHandling = useErrorHandling();
    const {message, modal, notification} = App.useApp();

    const [pageSize, setPageSize] = React.useState<number>(10);

    const [form] = Form.useForm();
    const [data, setData] = React.useState<T[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalItems, setTotalItems] = React.useState<number>(0);
    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const [sorter, setSorter] =
        React.useState<{field: string; reverse: boolean}>();
    const [search, setSearch] = React.useState<{[v: string]: string}>({});
    const [editableItem, setEditableItem] = React.useState<string | null>(null);

    const deleteMode = props.deleteMode ?? 'single';

    const fetchData = () => {
        setSelectedItems([]);
        setEditableItem(null);
        const page = props.find({
            sort: sorter?.field ?? (props.defaultSort || 'updatedAt'),
            direction: sorter
                ? sorter.reverse
                    ? 'DESC'
                    : 'ASC'
                : props.defaultSortDirection || 'DESC',
            ...search,
        });
        page.setPageSize(pageSize);
        page.getPage(currentPage)
            .then(async data => {
                const total = await page.getTotal();
                setData(data);
                setTotalItems(total);
                props.onFetch && props.onFetch(data, total);
            })
            .catch(errorHandling);
    };

    useInvalidateListener(fetchData);

    React.useEffect(fetchData, [
        currentPage,
        pageSize,
        sorter,
        JSON.stringify(search),
    ]);

    React.useEffect(() => {
        const found = data.find(r => r.id == editableItem);
        if (found) {
            form.setFieldsValue({...found});
            setSelectedItems([found.id]);
        } else {
            form.resetFields();
            setSelectedItems([]);
        }
    }, [editableItem]);

    const save = async () => {
        let items = {};
        try {
            items = await form.validateFields();
        } catch (e: any) {
            console.error('Failed on validateFields', e);
            return;
        }

        const row = {
            ...data.find(r => r.id == editableItem),
            ...(items as E),
        } as E;
        try {
            if (editableItem) {
                if (editableItem == emptyId()) {
                    row.id = editableItem;
                    props.create && (await props.create(row));
                } else {
                    props.update && (await props.update(row));
                }
            }
            setEditableItem(null);
        } catch (e: any) {
            errorHandling(e);
        }
        fetchData();
    };

    const onAddButton = () => {
        if (props.customOnCreate) {
            props.customOnCreate();
            return;
        }

        setData([
            {
                ...props.newEmpty(),
                id: emptyId(),
            },
            ...data,
        ]);
        setEditableItem(emptyId());
        form.resetFields();
    };
    const deleteItems = async () => {
        const items = [...selectedItems];
        try {
            for (const item of items) {
                props.delete &&
                    (await props.delete(data.find(r => r.id == item) as T));
            }
            setSelectedItems([]);
        } catch (e: any) {
            errorHandling(e);
        }
        fetchData();
    };

    if (props.actionsRef) {
        props.actionsRef.current = {
            setSelection: (items: T[]) =>
                setSelectedItems(items.map(i => i.id)),
            getSelection: () =>
                selectedItems.map(id => data.find(r => r.id == id) as T),
            invalidate: fetchData,
            triggerCreateNew: onAddButton,
        };
    }

    const columns: EditableColumnType<T>[] = [
        ...props.columns,
        {
            title: '',
            dataIndex: '',
            key: 'action',
            weight: 1.5,
            render: (_: string, record: T) => {
                if (editableItem == record.id && props.modal) {
                    return <></>;
                }

                if (editableItem == record.id) {
                    return (
                        <Space direction={'vertical'}>
                            <Button
                                size={'small'}
                                type={'link'}
                                onClick={() => save().then()}>
                                {t('edit.save')}
                            </Button>
                            <Button
                                type={'link'}
                                size={'small'}
                                onClick={() => {
                                    setEditableItem(null);
                                    fetchData();
                                }}>
                                {t('edit.abort')}
                            </Button>
                        </Space>
                    );
                } else {
                    let extraActions = props.extraActions ?? [];
                    if (props.delete && deleteMode == 'single') {
                        extraActions = [
                            ...extraActions,
                            {
                                label: t('edit.delete'),
                                action: (record: T) => {
                                    modal
                                        .confirm({
                                            title: t(
                                                'translation:edit.reallyDelete',
                                                {
                                                    count: 1,
                                                    item: props.itemName(1),
                                                },
                                            ),
                                        })
                                        .then(
                                            confirmed => {
                                                confirmed &&
                                                    props.delete &&
                                                    props
                                                        .delete(record)
                                                        .catch(errorHandling)
                                                        .then(fetchData);
                                            },
                                            () => 42,
                                        );
                                },
                            },
                        ];
                    }

                    const items = extraActions.map((action, index) => ({
                        label: action.label,
                        key: index,
                    }));
                    const onClick = (e: {key: string}) =>
                        extraActions[parseInt(e.key)].action(record);

                    const onClickMainAction = () => {
                        if (props.actionButton?.action) {
                            props.actionButton.action(record);
                        } else {
                            setEditableItem(record.id);
                        }
                    };
                    if (items.length > 0) {
                        return (
                            <Dropdown.Button
                                menu={{items, onClick}}
                                onClick={onClickMainAction}
                                disabled={!!editableItem}>
                                {props.actionButton?.label ?? t('edit.edit')}
                            </Dropdown.Button>
                        );
                    } else {
                        return (
                            <Button
                                onClick={onClickMainAction}
                                disabled={!!editableItem}>
                                {props.actionButton?.label ?? t('edit.edit')}
                            </Button>
                        );
                    }
                }
            },
        },
    ];

    if (!props.update && !props.extraActions && !props.actionButton) {
        columns.pop();
    }

    const rowSelection: TableRowSelection<T> = {
        type: 'checkbox',
        selectedRowKeys: selectedItems,
        onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
            if (!editableItem) {
                setSelectedItems(selectedRowKeys as string[]);
            }
        },
        getCheckboxProps: (record: T) => ({
            disabled: false,
            name: record.id,
        }),
    };

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const totalWidth = columns
        .map(c => c.weight ?? 1)
        .reduce((a, b) => a + b, 0);

    const mergedColumns: EditableColumnType<T>[] = columns.map(col => {
        return {
            ...col,
            width: ((col.weight ?? 1) / totalWidth) * 100 + '%',
            onCell: (record: T): EditableCellProps<T> => {
                return {
                    record,
                    column: col,
                    dataIndex: col.dataIndex as keyof T,
                    editable:
                        editableItem === record.id && !props.modal
                            ? col.editable ?? false
                            : false,
                    useSkeleton: !!(editableItem === record.id && props.modal),
                    children: [],
                };
            },
        };
    });

    const showDeleteButton = selectedItems.length > 0 && !editableItem;
    const showAddButton =
        !showDeleteButton &&
        !editableItem &&
        (!!props.create || !!props.customOnCreate);
    const showSearchbar =
        props.searchbarItems && props.searchbarItems.length > 0;
    const showTopBar = showDeleteButton || showAddButton || showSearchbar;

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            {showTopBar && (
                <div style={{width: '100%', display: 'flex'}}>
                    <Hideable shown={showDeleteButton}>
                        <Popconfirm
                            title={t('edit.reallyDelete', {
                                count: selectedItems.length,
                                item: props.itemName(selectedItems.length),
                            })}
                            onConfirm={deleteItems}>
                            <Button
                                type={'primary'}
                                style={{marginRight: '1rem'}}>
                                {' '}
                                {t('edit.delete')} {`(${selectedItems.length})`}
                            </Button>
                        </Popconfirm>
                    </Hideable>
                    {showSearchbar ? (
                        <Searchbar
                            style={{flex: 1}}
                            onChange={map => {
                                setSearch(map);
                            }}
                            items={props.searchbarItems}
                        />
                    ) : (
                        <div style={{flex: 1}} />
                    )}
                    <Hideable shown={showAddButton}>
                        <Button
                            type={'primary'}
                            style={{marginLeft: '1rem'}}
                            onClick={onAddButton}>
                            {t('edit.add')}
                        </Button>
                    </Hideable>
                </div>
            )}

            <Form form={form} component={false}>
                {props.modal && editableItem && (
                    <Modal
                        open={true}
                        onCancel={() => {
                            setEditableItem(null);
                            fetchData();
                        }}
                        onOk={save}
                        okText={t('edit.save')}
                        {...props.modalProps}>
                        {props.modal({
                            existing:
                                editableItem == emptyId()
                                    ? undefined
                                    : data.find(r => r.id == editableItem),
                            onFinish: save,
                            onAbort: () => {
                                setEditableItem(null);
                                fetchData();
                            },
                        })}
                    </Modal>
                )}
                <Table
                    components={components}
                    scroll={{x: 1500}}
                    rowKey={'id'}
                    rowSelection={
                        props.delete && deleteMode == 'multiple'
                            ? rowSelection
                            : undefined
                    }
                    onChange={(page, filter, sorter) => {
                        if (!Array.isArray(sorter)) {
                            const column = sorter.column as
                                | EditableColumnType<T>
                                | undefined;
                            const fieldName =
                                column?.sorterKey || sorter.field + '';

                            if (sorter.order == 'ascend') {
                                setSorter({
                                    field: fieldName,
                                    reverse: false,
                                });
                            } else if (sorter.order == 'descend') {
                                setSorter({
                                    field: fieldName,
                                    reverse: true,
                                });
                            } else {
                                setSorter(undefined);
                            }
                        }
                    }}
                    style={{width: '100%'}}
                    columns={mergedColumns}
                    dataSource={data}
                    pagination={false}
                />
            </Form>
            <Space
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <Typography.Text>
                    {t('edit.count', {
                        count: totalItems,
                    })}{' '}
                </Typography.Text>
                <Pagination
                    current={currentPage}
                    onChange={setCurrentPage}
                    onShowSizeChange={(_, size) => {
                        const offset = (currentPage - 1) * pageSize;
                        setCurrentPage(Math.floor(offset / size) + 1);
                        setPageSize(size);
                    }}
                    total={totalItems}
                    pageSize={pageSize}
                />
            </Space>
        </Space>
    );
};
