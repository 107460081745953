import {Form, Select, Typography} from 'antd';
import {
    AlertOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {AlertLevel} from '../../../model/Alert';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const InputItemAlertLevel = () => {
    const {t} = useTranslation();

    return (
        <>
            <Form.Item
                name={['document', 'level']}
                label={t('translation:alert.level')}>
                <Select
                    options={[
                        {
                            label: (
                                <>
                                    <AlertOutlined />{' '}
                                    {t('translation:alert.levelName.warn')}
                                </>
                            ),
                            value: AlertLevel.WARN,
                        },
                        {
                            label: (
                                <>
                                    <WarningOutlined />{' '}
                                    {t('translation:alert.levelName.info')}
                                </>
                            ),
                            value: AlertLevel.INFo,
                        },
                        {
                            label: (
                                <>
                                    <InfoCircleOutlined />{' '}
                                    {t('translation:alert.levelName.silent')}
                                </>
                            ),
                            value: AlertLevel.SILENT,
                        },
                    ]}
                />
            </Form.Item>
            <Typography.Paragraph type={'secondary'}>
                Die Priorität bestimmt, welche Art von Benachrichtigung
                versendet wird. Ein Hinweis erzeugt eine lautlose
                Benachrichtigung im Hintergrund. Bei einer Information wird eine
                normale Benachrichtigung versendet. Die Warnung durchbricht ggf.
                den Stummschaltungsmodus.
            </Typography.Paragraph>
        </>
    );
};
