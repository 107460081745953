import {Employee} from './Employee';
import {EmergencyContact} from './EmergencyContact';
import {ReceiverGroup} from './ReceiverGroup';

export interface Alert extends AlertDocument {
    id: string;
    createdAt: string;
    updatedAt: string;
    creatorId: string;
    validFrom: string;
    validUntil: string;
    filterType: string;
    contactIds: string[];
    receiverGroups?: ReceiverGroup[];
    imageBlobId: string;
    //
    creator?: Employee;
    updater?: Employee;
    contacts: EmergencyContact[];
}

export interface AlertDocument {
    level: AlertLevel;
    title: string;
    icon?: string;
    description: string;
    instructions: string;
}

export enum AlertLevel {
    WARN = 'warn',
    INFo = 'info',
    SILENT = 'silent',
}

export const AllAlertLevels = [
    AlertLevel.WARN,
    AlertLevel.INFo,
    AlertLevel.SILENT,
];

export interface CreateAlert {
    document: AlertDocument;
    target: {
        receiverGroupIds?: string[];
    };
    contactIds: string[];
    image?: string; // base64 encoded
    imageBlobId?: string;
    validFrom: string;
    validUntil: string;
}
