import {App, Button, Form, FormInstance, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {PasswordInput} from '../../../features/auth/component/PasswordInput';
import {useState} from 'react';
import {useErrorHandling} from '../../error/ErrorHandlerProvider';
import {Api} from '../../../api/endpoints';

interface ChangePasswordForm {
    password: string;
    newPassword: string;
    newPasswordRepeat: string;
}

export const ChangePasswordModal = (props: {onClose?: () => void}) => {
    const {t} = useTranslation();
    const errorHandling = useErrorHandling();
    const [passwordNewVisible, setPasswordNewVisible] = useState(false);
    const {notification} = App.useApp();

    const onFinish = (values: ChangePasswordForm) => {
        Api.changePassword(values.newPassword, values.password)
            .then(() => {
                props.onClose?.();
                notification.success({
                    message: t('passwordChange.success'),
                    placement: 'top',
                });
            })
            .catch(errorHandling);
    };

    return (
        <div>
            <Typography.Title level={4}>
                {t('passwordChange.heading')}
            </Typography.Title>
            <Typography.Paragraph>
                {t('passwordChange.body')}
            </Typography.Paragraph>
            <Form layout={'vertical'} onFinish={onFinish}>
                <Form.Item
                    name="password"
                    label={t('passwordChange.oldPassword')}
                    rules={[
                        {
                            required: true,
                            message: t(
                                'translation:passwordChange.passwordOldRequired',
                            ),
                        },
                    ]}>
                    <PasswordInput />
                </Form.Item>
                <div style={{height: '1rem'}} />
                <Form.Item
                    name="newPassword"
                    label={t('passwordChange.newPassword')}
                    rules={[
                        {
                            required: true,
                            message: t(
                                'translation:passwordChange.passwordNewRequired',
                            ),
                        },
                        {
                            min: 8,
                            message: t(
                                'translation:passwordChange.passwordLengthError',
                                {n: 8},
                            ),
                        },
                    ]}>
                    <PasswordInput
                        visible={passwordNewVisible}
                        setVisible={setPasswordNewVisible}
                    />
                </Form.Item>
                <Form.Item
                    name="newPasswordRepeat"
                    rules={[
                        {
                            required: true,
                            message: t(
                                'translation:passwordChange.passwordNewRequired',
                            ),
                        },
                        formInstance => ({
                            message: t(
                                'translation:passwordChange.passwordRepeatError',
                            ),
                            validator: (_, value) => {
                                if (
                                    formInstance.getFieldValue(
                                        'newPassword',
                                    ) !== value
                                ) {
                                    return Promise.reject(new Error());
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}>
                    <PasswordInput visible={passwordNewVisible} repeat={true} />
                </Form.Item>
                <Form.Item>
                    <Space
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}>
                        <Button onClick={props.onClose}>
                            {t('edit.close')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('edit.ok')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};
