import {CSVEffectedRow, CSVSyncRow} from '../../../model/core';
import {useTranslation} from 'react-i18next';
import {Button, Descriptions, Space, Spin, theme, Typography} from 'antd';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import React from 'react';
import {Api} from '../../../api/endpoints';
import {CsvSyncContentContainer} from './ContentContainer';

export const CsvStepShowResult = (props: {
    processedCsv: CSVSyncRow[];
    onFinish: (effect: CSVEffectedRow) => void;
    onAbort: () => void;
    onBack?: () => void;
}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();
    const handleError = useErrorHandling();

    const [result, setResult] = React.useState<CSVEffectedRow>();
    const [wait, setWait] = React.useState(false);

    React.useEffect(() => {
        Api.csvSyncDryRun(props.processedCsv)
            .then(result => {
                setResult(result);
                setTimeout(() => setWait(true), 3000);
            })
            .catch(handleError);
    }, []);

    return (
        <Space style={{width: '100%', height: '100%'}} direction={'vertical'}>
            <CsvSyncContentContainer>
                {!result && (
                    <Space style={{width: '100%', justifyContent: 'center'}}>
                        <Spin />
                    </Space>
                )}
                {result && (
                    <Space
                        size={'large'}
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            minHeight: '30rem',
                            justifyContent: 'center',
                        }}
                        direction={'vertical'}>
                        <Typography.Paragraph>
                            {t('csvSync.step.check.changesDesc')}
                            {':'}
                        </Typography.Paragraph>

                        <div
                            style={{
                                backgroundColor: token.colorBgBase,
                                borderRadius: '0.5rem',
                            }}>
                            <Descriptions
                                column={1}
                                bordered
                                style={{width: '20rem'}}>
                                <Descriptions.Item
                                    label={t(
                                        'translation:csvSync.step.check.updated',
                                    )}>
                                    <b>{result.updated}</b>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t(
                                        'translation:csvSync.step.check.created',
                                    )}>
                                    <b>{result.added}</b>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t(
                                        'translation:csvSync.step.check.deleted',
                                    )}>
                                    <b>{result.deleted}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <Button
                            type={'primary'}
                            loading={!wait}
                            onClick={() => wait && props.onFinish(result)}>
                            {t('csvSync.step.check.performAction')}
                        </Button>
                    </Space>
                )}
            </CsvSyncContentContainer>
            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={props.onAbort}>{t('edit.abort')}</Button>
                <Button onClick={props.onBack} type={'primary'}>
                    {t('edit.back')}
                </Button>
            </Space>
        </Space>
    );
};
