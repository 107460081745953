import {Employee} from '../../../model/Employee';
import React, {MutableRefObject} from 'react';
import {Api} from '../../../api/endpoints';
import {useTranslation} from 'react-i18next';
import {emptyId} from '../../../model/common';
import {SearchBarItem} from '../../../components/table/Searchbar';
import {
    EditableColumnType,
    EditableTable,
} from '../../../components/table/EditTable';
import {Regex} from '../../../common/regex';
import {ReceiverGroup} from '../../../model/ReceiverGroup';
import {App, Select, Tag} from 'antd';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {ReceiverGroupTag} from '../../../components/model/receiverGroupTag';

export const EditEmployeeDataTable = () => {
    const {t} = useTranslation();
    const handleError = useErrorHandling();
    const {notification} = App.useApp();

    const [groups, setGroups] = React.useState<ReceiverGroup[]>();

    React.useEffect(() => {
        Api.receiverGroup.list().then(setGroups).catch(handleError);
    }, []);

    let columns: EditableColumnType<Employee>[] = [
        {
            fixed: 'left',
            sorter: true,
            editable: true,
            required: false,
            dataIndex: 'employeeNumber',
            title: t('employee.employeeNumberShort'),
            key: 'employeeNumber',
            weight: 1,
        },
        {
            sorter: true,
            editable: true,
            required: true,
            dataIndex: 'firstName',
            key: 'firstName',
            weight: 1.5,
        },
        {
            sorter: true,
            editable: true,
            required: true,
            dataIndex: 'lastName',
            key: 'lastName',
            weight: 1.5,
        },
        {
            sorter: true,
            editable: true,
            required: false,
            dataIndex: 'department',
            key: 'department',
            weight: 1.5,
        },
        {
            sorter: true,
            editable: true,
            required: true,
            regex: Regex.Email,
            dataIndex: 'email',
            key: 'email',
            weight: 3.5,
        },
        {
            sorter: true,
            editable: true,
            required: false,
            dataIndex: 'phone',
            key: 'phone',
            weight: 1.5,
        },
        {
            sorter: false,
            editable: {
                content: record => (
                    <Select
                        mode="multiple"
                        allowClear={true}
                        style={{width: '100%'}}
                        placeholder={t('employee.receiverGroups')}
                        defaultValue={record.receiverGroups.map(
                            r => r.receiverGroup.id,
                        )}
                        options={(groups || []).map(g => ({
                            label: g.name,
                            value: g.id,
                        }))}
                    />
                ),
            },
            required: false,
            dataIndex: 'receiverGroupIds',
            key: 'receiverGroups',
            render: (_, record) => {
                const thisGroups = record.receiverGroups.map(
                    r => r.receiverGroup,
                );
                thisGroups.sort((a, b) => a.name.localeCompare(b.name));
                return thisGroups.map(v => (
                    <ReceiverGroupTag receiverGroup={v} />
                ));
            },
            weight: 2.5,
        },
        {
            sorter: true,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
        {
            sorter: true,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: value => t('util.date', {date: value}),
            weight: 1.5,
        },
        {
            sorter: true,
            dataIndex: 'importSource',
            key: 'importSource',
            render: value =>
                value ? t(`employee.importSourceName.${value}`) : '',
            weight: 1.5,
        },
    ];

    columns = columns.map(col => {
        col.title = col.title || t(`employee.${col.key}`);
        return col;
    });

    const searchItems: SearchBarItem[] = [
        {
            value: 'inDepartment',
        },
        {
            value: 'inEmployeeNumber',
        },
        {
            value: 'inName',
        },
        {
            value: 'inEmail',
        },
        {
            value: 'inPhone',
            label: 'Telefonnummer',
        },
        {
            value: 'byImportSource',
            select: [
                {
                    value: 'ad',
                    label: t('translation:employee.importSourceName.ad'),
                },
                {
                    value: 'csv',
                    label: t('translation:employee.importSourceName.csv'),
                },
                {
                    value: 'manual',
                    label: t('translation:employee.importSourceName.manual'),
                },
            ],
        },
        {
            label: t('translation:employee.receiverGroup'),
            value: 'byReceiverGroupId',
            select: (groups || []).map(g => ({
                value: g.id,
                label: g.name,
            })),
        },
    ].map(item => {
        return {
            value: item.value,
            label:
                item.label ||
                t(
                    `employee.${
                        item.value[2].toLowerCase() + item.value.slice(3)
                    }`,
                ),
            select: item.select,
        };
    });

    interface ExEmployee extends Employee {
        receiverGroupIds?: string[];
    }

    return (
        <EditableTable
            searchbarItems={searchItems}
            columns={columns}
            deleteMode={'multiple'}
            newEmpty={() => ({
                id: emptyId(),
                employeeNumber: '',
                firstName: '',
                lastName: '',
                department: '',
                email: '',
                phone: '',
                importSource: '',
                receiverGroups: [],
            })}
            extraActions={[
                {
                    label: t('employee.reinvite'),
                    action: (selected: Employee) => {
                        Api.employee
                            .reinvite(selected.id)
                            .then(() => {
                                notification.success({
                                    message: t('employee.reinviteSuccess'),
                                });
                            })
                            .catch(handleError);
                    },
                },
            ]}
            find={params => Api.employee.find(100, params)}
            create={async (e: ExEmployee) => {
                const selectedGroups = (groups || []).filter(
                    g => (e.receiverGroupIds ?? []).indexOf(g.id) !== -1,
                );
                await Api.employee.post(e, selectedGroups);
            }}
            update={async (e: ExEmployee) => {
                const selectedGroups = (groups || []).filter(
                    g => (e.receiverGroupIds ?? []).indexOf(g.id) !== -1,
                );
                await Api.employee.put(e, selectedGroups);
            }}
            delete={async (e: Employee) => {
                await Api.employee.delete(e.id);
            }}
            itemName={count => t('employee.item', {count})}
        />
    );
};
