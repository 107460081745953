import {Card, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {EditEmployeeDataTable} from './component/EditEmployeeTable';
import {CSVCard} from './component/CSVCard';
import {InvalidateReceiver} from '../../components/invalidate/Invalidate';
import React from 'react';
import {EditManagerDataTable} from '../manager/component/EditManagerDataTable';

export const EmployeePage = () => {
    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Title>Mitarbeiter</Typography.Title>

            <CSVCard style={{maxWidth: '40rem'}} />

            <Card>
                <EditEmployeeDataTable />
            </Card>
        </Space>
    );
};
