import {
    Button,
    Card,
    Descriptions,
    DescriptionsProps,
    Form,
    Input,
    Space,
    Typography,
} from 'antd';
import React from 'react';
import {EditIconInput} from '../../components/input/EditIconInput';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../auth/AuthProvider';
import {Api} from '../../api/endpoints';
import SkeletonImage from 'antd/es/skeleton/Image';
import {ImageSelector} from '../../components/input/ImageSelector';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {ObjectSelect} from '../../components/input/ObjectSelect';
import {EditOutlined} from '@ant-design/icons';
import {EmergencyContact} from '../../model/EmergencyContact';
import {CSVModal} from '../csvImport/CSVModal';

export const CompanySettings = () => {
    const {t} = useTranslation();
    const [edited, setEdited] = React.useState(false);

    const auth = useAuth();
    const [loading, setLoading] = React.useState(false);
    const errorHandling = useErrorHandling();
    const [reload, setReload] = React.useState(false);

    // TODO: Edit backend to get company resovled in user / device
    const company = auth.user?.company;

    const fetchEmergencyContacts = async (search: string) => {
        try {
            return await Api.emergencyContact
                .find(100, {
                    mode: 'or',
                    inName: search,
                    inEmail: search,
                })
                .next();
        } catch (e: any) {
            errorHandling(e);
            return [];
        }
    };

    if (!company) {
        return <></>;
    }

    if (reload) {
        window.setTimeout(() => {
            setReload(false);
        }, 1);
        // trigger reset and reload of form
        return <p></p>;
    }

    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: t('company.name'),
            children: (
                <Form.Item name={'name'} noStyle>
                    <EditIconInput onUnlock={() => setEdited(true)}>
                        <Input />
                    </EditIconInput>
                </Form.Item>
            ),
        },
        {
            key: 'primaryContact',
            label: t('company.primaryContact'),
            children: (
                <>
                    <Form.Item name={'primaryContactId'} noStyle>
                        <ObjectSelect
                            showOnlyLabelWhenDisabled
                            disabled={!edited}
                            style={{width: '80%'}}
                            fetch={fetchEmergencyContacts}
                            renderItem={e => <RenderContact contact={e} />}
                        />
                    </Form.Item>
                    <span style={{width: 32}}> </span>
                    {!edited && (
                        <EditOutlined onClick={() => setEdited(true)} />
                    )}
                </>
            ),
        },
        {
            key: 'logo',
            label: t('company.logo'),
            children: (
                <Form.Item name={'logo'} noStyle>
                    <EditIconInput
                        onUnlock={() => setEdited(true)}
                        render={(_: string | undefined) => {
                            if (company?.logoId) {
                                return (
                                    <div
                                        style={{
                                            width: 336,
                                            height: 336,
                                            padding: 16,
                                            borderRadius: 8,
                                            display: 'inline-block',
                                            background:
                                                'linear-gradient(-45deg, hsla(350, 96%, 43%, 0.3), hsla(380, 96%, 43%, 0.3))',
                                        }}>
                                        <img
                                            src={Api.imageBlobUrl(
                                                company?.logoId,
                                            )}
                                            alt={t('company.logo')}
                                            style={{
                                                width: 300,
                                                height: 300,
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <SkeletonImage
                                        style={{
                                            width: 300,
                                            height: 300,
                                        }}
                                    />
                                );
                            }
                        }}>
                        <ImageSelector
                            width={512}
                            aspectRatio={[1, 1]}
                            style={{
                                width: 300,
                                height: 256,
                                background:
                                    'linear-gradient(-45deg, rgba(113, 97, 239, 0.3), rgba(170, 160, 253, 0.3))',
                                padding: 16,
                            }}
                        />
                    </EditIconInput>
                </Form.Item>
            ),
        },
    ];

    return (
        <Space direction={'vertical'} size={'large'}>
            <Typography.Title>Unternehmensdaten</Typography.Title>

            <Card
                title={t('companySettings.card.title')}
                style={{maxWidth: '60rem'}}>
                <Typography.Paragraph>
                    {t('companySettings.card.description')}
                </Typography.Paragraph>
            </Card>

            <Card>
                <Form
                    initialValues={{
                        name: company.name,
                        primaryContactId: company.primaryContactId,
                    }}
                    onFinish={values => {
                        setLoading(true);
                        Api.company
                            .update(values)
                            .then(auth.refetchUser)
                            .catch(errorHandling)
                            .finally(() => {
                                window.setTimeout(() => {
                                    setLoading(false);
                                    setReload(true);
                                }, 500);
                            });
                    }}>
                    <Descriptions items={items} bordered column={1} />

                    {edited && (
                        <Space
                            style={{
                                width: '100%',
                                maxWidth: '80rem',
                                display: 'flex',
                                justifyContent: 'right',
                            }}>
                            <Button htmlType="reset">
                                {t('translation:edit.abort')}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}>
                                {t('translation:edit.save')}
                            </Button>
                        </Space>
                    )}
                </Form>
            </Card>
        </Space>
    );
};

const RenderContact = (props: {contact: EmergencyContact}) => {
    return (
        <>
            {props.contact.employee.firstName} {props.contact.employee.lastName}{' '}
            {', '}
            <small>{props.contact.role + ' '}</small>
            {props.contact.employee.department && (
                <small>
                    {', '}
                    {props.contact.employee.department}
                </small>
            )}
        </>
    );
};
